export const Parametros = {
  mes: 1,
  anio: 2,
  importe: 3,
  litros: 4,
};

export const Today = new Date();

export const MESES = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

export function getDaysInMonthUTC(year, month) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(date.getDate());
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export const getTotal = (_unit, _data) => {
  return _unit === Parametros.importe
    ? _data?.map((v) => v.importe).reduce((a, b) => a + b, 0)
    : _data?.map((v) => v.value).reduce((a, b) => a + b, 0);
};

export const getTotalDet = (_unit, _data) => {
  var _total = 0;
  _data.map((d) => {
    let _values = _unit === Parametros.importe ? d.importe : d.volumen;
    _total += _values.reduce((a, b) => a + b, 0);
  });
  return _total;
};

export const daysInMonth = (year, month) => {
  var date = new Date(year, month, 1);
  var days = 1;
  while (date.getMonth() === month) {
    days = date.getDate();
    date.setDate(days + 1);
  }
  return days;
};

export const formatNumber = (num) => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "K";
  }
  return num.toString();
};

export const getParametrosFecha = (_per) => {
  const _year = Today.getFullYear();
  const _month = Today.getMonth();
  var params = {
    today: Today,
    year: _year,
    month: _month,
    fechaIni: new Date(_year, _per == Parametros.anio ? 0 : _month, 1),
    fechaFin: new Date(_year, _month, Today.getDate()),
  };

  return params;
};
