import { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { decimalVal, monedaMX, useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectByGlobalTurno } from "../../../../store/operaciones/liquidaciones";
import { hasDispatchError } from "../../../../api/services/service";
import {
  setLecturaProductoModificado,
  setJarreoConsignacionModificados,
} from "../../../../store/operaciones/liquidaciones";
import { LoadingPanel } from "@paul-igas/igas-react-components";
import { TableCombustible, TableCombustibleMobile } from "../combustibleStep";
import { CombustibleDetailDialog, CombustibleEditDialog, LecturasEditDialog } from "../combustibleStep/dialogs";

export function CombustibleGlobalStep({ data }) {
  const {
    loading,
    lecturas,
    combustible,
    combustibleOriginal,
    totales,
    esUltimoTurno,
    editDialog,
    openEditDialog,
    onSubmitEditDialog,
    editLecturasDialog,
    openEditLecturasDialog,
    onSubmitLecturasEditDialog,
    modalDetail,
    openDetailDialog,
  } = useCombustibleGlobalStep(data);

  const isMobile = data.screen === "xs" || data.screen === "sm";

  return (
    <Paper sx={{ mt: 1, mb: 1 }}>
      {loading && <LoadingPanel title='Cargando...' />}

      {!loading && isMobile && (
        <TableCombustibleMobile
          lecturas={lecturas}
          openEditDialog={openEditDialog}
          openEditLecturasDialog={openEditLecturasDialog}
          openDetailDialog={openDetailDialog}
          esUltimoTurno={esUltimoTurno}
          totales={totales}
          screen={data.screen}
        />
      )}

      {!loading && !isMobile && (
        <TableCombustible
          lecturas={lecturas}
          openEditDialog={openEditDialog}
          openEditLecturasDialog={openEditLecturasDialog}
          esUltimoTurno={esUltimoTurno}
          totales={totales}
        />
      )}

      <CombustibleDetailDialog open={modalDetail.isShowing} onClose={modalDetail.close} data={combustible} />
      <CombustibleEditDialog
        open={editDialog.isShowing}
        onClose={editDialog.close}
        postSubmit={onSubmitEditDialog}
        data={combustible}
        combustibleOriginal={combustibleOriginal}
      />
      <LecturasEditDialog
        open={editLecturasDialog.isShowing}
        onClose={editLecturasDialog.close}
        postSubmit={onSubmitLecturasEditDialog}
        data={combustible}
      />
    </Paper>
  );
}

const useCombustibleGlobalStep = (data) => {
  const dispatch = useDispatch();
  const { loading, lecturaProductoModificado } = useSelector((state) => state.liquidaciones);
  const { turno, isla, desactivaStepBtn, activaStepBtn, asignaIsla } = data;
  const [combustible, setCombustible] = useState({});
  const [combustibleOriginal, setCombustibleOriginal] = useState({});
  const [lecturasOriginal, setLecturasOriginal] = useState([]);
  const editDialog = useModal(false);
  const editLecturasDialog = useModal(false);
  const modalDetail = useModal(false);

  const openDetailDialog = (row) => {
    setCombustible(row);
    modalDetail.open();
  };

  const openEditDialog = (row) => {
    let tmp = [...lecturasOriginal];
    const index = tmp.findIndex((p) => p.id === row.id);
    const rowOriginal = tmp[index];
    setCombustible(row);
    setCombustibleOriginal(rowOriginal);
    editDialog.open();
  };

  const onSubmitEditDialog = (newRow) => {
    const { consignacion = () => {}, devolucion = () => {}, diferencia = () => {}, importe = () => {} } = newRow;
    let index = -1;
    //Se valida si el registro modificado corresponde a un detalle
    if (newRow.idDet > 0) {
      index = isla.lecturas.findIndex((p) => p.id === newRow.id);
      index += newRow.idDet ?? 0;
    } else index = isla.lecturas.findIndex((p) => p.id === newRow.id);
    let tmp = isla.lecturas.slice();
    tmp[index] = {
      ...isla.lecturas[index],
      consignacion,
      devolucion,
      diferencia,
      importe,
    };
    asignaIsla({ ...isla, lecturas: tmp });
    dispatch(setJarreoConsignacionModificados(true));
  };

  const openEditLecturasDialog = (row) => {
    setCombustible(row);
    editLecturasDialog.open();
  };

  const onSubmitLecturasEditDialog = (newRow) => {
    const { lecturaFinal = () => {}, diferencia = () => {}, importe = () => {} } = newRow;
    let index = -1;
    //Se valida si el registro modificado corresponde a un detalle
    if ((newRow.idDet ?? 0) > 0) {
      index = isla.lecturas.findIndex((p) => p.id === newRow.id);
      index += newRow.idDet ?? 0;
    } else index = isla.lecturas.findIndex((p) => p.id === newRow.id);
    let tmp = isla.lecturas.slice();
    tmp[index] = { ...isla.lecturas[index], lecturaFinal, diferencia, importe };
    asignaIsla({ ...isla, lecturas: tmp });
    if (!data.esUltimoTurno) {
      dispatch(setLecturaProductoModificado(true));
    }
  };

  const lecturasDisplay = function (xs, key) {
    if (!xs) return [];
    const group = xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    const groupArray = Object.values(group);
    return groupArray.map((grp) => ({
      id: grp[0].id,
      noDispensario: zeroPad(grp[0].noDispensario, 2),
      noManguera: zeroPad(grp[0].noManguera, 2),
      nombreCombustible: grp[0].nombreCombustible,
      lecturaInicial: grp[0].lecturaInicial,
      lecturaFinal: grp[grp.length - 1].lecturaFinal,
      idCombustible: grp[0].idCombustible,
      devolucion: grp.reduce((a, b) => a + b.devolucion, 0),
      consignacion: grp.reduce((a, b) => a + b.consignacion, 0),
      diferencia: grp.reduce((a, b) => a + b.diferencia, 0),
      diferenciaLecturas: grp.reduce((a, b) => a + b.diferenciaLecturas, 0),
      diferenciaLecturas2: grp.reduce((a, b) => a + (b.diferenciaLecturas2 ?? 0), 0),
      precioCombustible: grp.length === 1 ? grp[0].precioCombustible : "-",
      importe: grp.reduce((a, b) => a + b.importe, 0),
      detalle:
        grp.length === 1
          ? null
          : grp.map((item, index) => ({
              id: index,
              lecturaInicial: item.lecturaInicial,
              lecturaFinal: item.lecturaFinal,
              idCombustible: item.idCombustible, 
              devolucion: item.devolucion,
              consignacion: item.consignacion,
              diferencia: item.diferencia,
              diferenciaLecturas: item.diferenciaLecturas,
              diferenciaLecturas2: item.diferenciaLecturas2 ?? 0,
              precioCombustible: item.precioCombustible,
              importe: item.importe,
            })),
    }));
  };

  function calculaVolumen(l) {
    return (
      l.lecturaFinal -
      l.lecturaInicial -
      l.devolucion -
      l.consignacion -
      l.diferenciaLecturas -
      (l.diferenciaLecturas2 ?? 0)
    );
  }

  const asignaLecturas = ({ payload }) => {
    /*Se hace un recalculado de la liquidación actual ya que,
      al agregar la liquidación sólo traía valores estáticos 
      y choca con el proceso de modif. de lecturas finales*/
    let nuevaData = payload?.map((l) => ({
      ...l,
      diferencia: calculaVolumen(l),
      importe: round(calculaVolumen(l) * l.precioCombustible, 2),
    }));
    asignaIsla({ ...isla, lecturas: nuevaData });
    setLecturasOriginal(nuevaData);
  };

  const initStep = () => {
    if (!isla.lecturas) {
      dispatch(selectByGlobalTurno({ fechaTurno: turno?.fecha, noTurno: turno?.noTurno }))
        .then(hasDispatchError)
        .then(asignaLecturas)
        .catch(() => {});
    } else {
      setLecturasOriginal(isla?.lecturas);
    }
  };

  useEffect(initStep, []);
  useEffect(() => {
    loading.getGlobalTurno ? desactivaStepBtn() : activaStepBtn();
  }, [loading]);

  return {
    loading: loading.getGlobalTurno,
    lecturas: lecturasDisplay(isla?.lecturas, "idManguera"),
    combustible,
    combustibleOriginal,
    totales: {
      volJarreo: decimalVal(3).format(isla.lecturas?.reduce((a, b) => a + b.devolucion, 0) ?? 0),
      volConsignacion: decimalVal(3).format(isla.lecturas?.reduce((a, b) => a + b.consignacion, 0) ?? 0),
      diferenciaLecturas: decimalVal(3).format(isla.lecturas?.reduce((a, b) => a + b.diferenciaLecturas, 0) ?? 0),
      volumen: decimalVal(3).format(isla.lecturas?.reduce((a, b) => a + b.diferencia, 0) ?? 0),
      importe: monedaMX.format(isla.lecturas?.reduce((a, b) => a + b.importe, 0) ?? 0),
    },
    editDialog,
    modalDetail,
    editLecturasDialog,
    lecturaProductoModificado: lecturaProductoModificado,
    openEditDialog,
    onSubmitEditDialog,
    openEditLecturasDialog,
    onSubmitLecturasEditDialog,
    openDetailDialog,
  };
};

const round = (num, decimal) => {
  return +(Math.round(num + "e+" + decimal) + "e-" + decimal);
};
