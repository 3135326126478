import store from "../../store";

export const showInWindow = ({ payload }) => {
  const blob = new Blob([payload], { type: "application/pdf" });
  const fileUrl = URL.createObjectURL(blob);
  const pdfWindow = window.open();
  pdfWindow.location.href = fileUrl;
};

export const downloadFile = ({ payload }) => {
  const state = store?.getState();
  let nombreReporte = state?.reportesLiq?.nombreReporte;
  const blob = new Blob([payload], { type: "application/xlsx" });
  var downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${nombreReporte}.xlsx`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
