import { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { decimalVal, useModal } from "@paul-igas/igas-react-hooks";
import { DangerMessage, SuccessMessage, IGasForm, IGasDecimalField } from "@paul-igas/igas-react-components";
import { useDispatch } from "react-redux";
import { setJarreoConsignacionModificados } from "../../../../../store/operaciones/liquidaciones";
import { GetConfiguration } from "../../../../helpers/variablesEstacion";

export const CombustibleEditDialog = ({ open, onClose, postSubmit, data, combustibleOriginal }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState();
  const successMsg = useModal(false);
  const dangerMsg = useModal(false);
  const VOLMAXTOTALIZADOR = GetConfiguration(3)?.valor;
  const manejaConsignacion = GetConfiguration(7)?.valor;

  const validaForm = (form) => {
    const newErrors = {};

    if (data.lecturaInicial > data.lecturaFinal) {
      const diff = Math.abs(VOLMAXTOTALIZADOR - data.lecturaInicial);
      const limit =
        diff + (parseFloat(data.lecturaFinal) - parseFloat(form.devolucion) - parseFloat(form.consignacion));
      newErrors.consignacion = manejaConsignacion
        ? form.consignacion.toString() === ""
          ? "'Vol. Consignación' es requerido."
          : ""
        : "";
      newErrors.devolucion = form.devolucion.toString() === "" ? "'Vol. Devolución' es requerido." : "";
      newErrors.limit = parseFloat(limit) < 0 ? "Volumen de venta no puede quedar en negativo" : "";
      if (newErrors.limit !== "") {
        dangerMsg.open();
      }
      setErrors(newErrors);
      return Object.values(newErrors).every((x) => x === "");
    } else {
      const limit =
        parseFloat(data.lecturaFinal) -
        parseFloat(data.lecturaInicial) -
        parseFloat(data.diferenciaLecturas) -
        parseFloat(data.diferenciaLecturas2 ?? 0) -
        parseFloat(form.devolucion ?? 0) -
        parseFloat(form.consignacion ?? 0);
      newErrors.consignacion = newErrors.consignacion = manejaConsignacion
        ? form.consignacion.toString() === ""
          ? "'Vol. Consignación' es requerido."
          : ""
        : "";
      newErrors.devolucion = form.devolucion.toString() === "" ? "'Vol. Devolución' es requerido." : "";
      newErrors.limit = parseFloat(limit) < 0 ? "Volumen de venta no puede quedar en negativo" : "";

      if (newErrors.limit !== "") {
        dangerMsg.open();
      }

      setErrors(newErrors);
      return Object.values(newErrors).every((x) => x === "");
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };
  const initDialog = () => {
    if (open) {
      setErrors({});
    }
  };

  const handleEdit = (comb) => {
    if (validaForm(comb)) {
      const devolucion = parseFloat(comb.devolucion);
      const consignacion = manejaConsignacion ? parseFloat(comb.consignacion) : data.consignacion;
      if (data.lecturaInicial > data.lecturaFinal) {
        const diff = Math.abs(VOLMAXTOTALIZADOR - data.lecturaInicial);
        const diferencia =
          diff +
          (data.lecturaFinal - devolucion - consignacion - data.diferenciaLecturas - data.diferenciaLecturas2 ?? 0);
        const importe = diferencia * data.precioCombustible;
        dispatch(setJarreoConsignacionModificados(true));
        const newData = {
          ...data,
          devolucion,
          consignacion,
          diferencia,
          importe,
        };
        onClose();
        successMsg.open();
        postSubmit(newData);
      } else {
        const diferencia =
          combustibleOriginal.diferencia -
            devolucion -
            consignacion -
            data.diferenciaLecturas -
            data.diferenciaLecturas2 ?? 0;
        const importe = diferencia * data.precioCombustible;
        dispatch(setJarreoConsignacionModificados(true));
        const newData = {
          ...data,
          devolucion,
          consignacion,
          diferencia,
          importe,
        };
        onClose();
        successMsg.open();
        postSubmit(newData);
      }
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>{manejaConsignacion ? "Editar jarreo y consignación" : "Editar jarreo"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{`Dispensario: ${data?.noDispensario} - ${data?.nombreCombustible}`}</DialogContentText>
          <br />
          <IGasForm id='combustible-edit-dlg' onSubmit={handleEdit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IGasDecimalField
                  autoFocus
                  label='Vol. Jarreo'
                  name='devolucion'
                  defaultValue={decimalVal(3).format(data?.devolucion)}
                  error={errors?.devolucion}
                  maxLength={10}
                  decimalScale={3}
                />
              </Grid>
              {manejaConsignacion && (
                <Grid item xs={12}>
                  <IGasDecimalField
                    label='Vol. Consignación'
                    name='consignacion'
                    defaultValue={decimalVal(3).format(data?.consignacion)}
                    error={errors?.consignacion}
                    maxLength={10}
                    decimalScale={3}
                  />
                </Grid>
              )}
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={false} type='submit' form='combustible-edit-dlg'>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={dangerMsg.close}
        text='Volumen de venta no puede quedar en negativo.'
      />
    </>
  );
};

export default CombustibleEditDialog;
