import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  createTheme,
  styled,
} from "@mui/material";
import AlertaLecturas from "./components/alertaLecturas";
import { useEffect, useState } from "react";
import { decimalVal, monedaMX } from "@paul-igas/igas-react-hooks";
import OptionTableRowComb from "./components/optionTableRowComb";

const CellHead = styled(TableCell)(() => ({ fontWeight: "bold", color: "black" }));

export const TableCombustibleMobile = (props) => {
  const { lecturas, openEditDialog, openEditLecturasDialog, openDetailDialog, esUltimoTurno, totales, screen } = props;

  if (lecturas?.length <= 0) return <AlertaLecturas />;

  return (
    <TableContainer>
      <Table size='small' padding='checkbox'>
        <TableHeadCombustibleMobile screen={screen} />
        <TableBody>
          {lecturas.map((lectura) => (
            <RowCombustibleMobile
              key={lectura.id}
              rowData={lectura}
              openEditDialog={openEditDialog}
              openEditLecturasDialog={openEditLecturasDialog}
              openDetailDialog={openDetailDialog}
              esUltimoTurno={esUltimoTurno}
              screen={screen}
            />
          ))}
        </TableBody>
        <TotalsCombustibleMobile totalsRow={totales} screen={screen} />
      </Table>
    </TableContainer>
  );
};

export default TableCombustibleMobile;

const TableHeadCombustibleMobile = ({ screen }) => {
  const [columns, setColumns] = useState(screen === "sm");

  useEffect(() => {
    setColumns(screen === "sm");
  }, [screen]);

  return (
    <TableHead sx={{ backgroundColor: "whitesmoke" }}>
      <TableRow>
        <CellHead>Dispensario</CellHead>
        {columns && (
          <>
            <CellHead align='right'>Lect. Inicial</CellHead>
            <CellHead align='right'>Lect. Final</CellHead>
          </>
        )}
        <CellHead align='right'>Volumen</CellHead>
        <CellHead align='right'>Importe</CellHead>
        <CellHead />
      </TableRow>
    </TableHead>
  );
};

const RowCombustibleMobile = (props) => {
  const { rowData, openEditDialog, openEditLecturasDialog, openDetailDialog, esUltimoTurno, screen } = props;
  const [columns, setColumns] = useState(screen === "sm");

  useEffect(() => {
    setColumns(screen === "sm");
  }, [screen]);

  return (
    <TableRow>
      <TableCellLink onDetail={openDetailDialog} data={rowData}>
        {rowData.noDispensario} - {rowData.nombreCombustible}
      </TableCellLink>
      {columns && (
        <>
          <TableCell align='right'>{decimalVal(3).format(rowData.lecturaInicial)}</TableCell>
          <TableCell align='right'>{decimalVal(3).format(rowData.lecturaFinal)}</TableCell>
        </>
      )}
      <TableCell align='right'>{decimalVal(3).format(rowData.diferencia)}</TableCell>
      <TableCell align='right'>{monedaMX.format(rowData.importe)}</TableCell>
      <TableCell align='center' padding='none'>
        <OptionTableRowComb
          data={rowData}
          onEdit={openEditDialog}
          onEditLecturas={openEditLecturasDialog}
          esUltimoTurno={esUltimoTurno}
        />
      </TableCell>
    </TableRow>
  );
};

const TableCellLink = (props) => {
  const { children, onDetail, data } = props;
  const theme = createTheme();

  const handleDetail = () => {
    onDetail(data);
  };

  return (
    <TableCell>
      <Link
        align='left'
        component='button'
        variant='body2'
        onClick={handleDetail}
        sx={{ color: theme.palette.info.main }}
        underline='hover'
      >
        {children}
      </Link>
    </TableCell>
  );
};

const TotalsCombustibleMobile = ({ totalsRow, screen }) => {
  const [columns, setColumns] = useState(screen === "sm");

  useEffect(() => {
    setColumns(screen === "sm");
  }, [screen]);

  return (
    <TableFooter>
      <TableRow>
        <CellHead variant='head' colSpan={columns ? 3 : 1} align='right'>
          Totales
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.volumen}
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.importe}
        </CellHead>
      </TableRow>
    </TableFooter>
  );
};
