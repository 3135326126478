import { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Button, Grid } from "@mui/material";
import { useModal } from "@paul-igas/igas-react-hooks";
import { SuccessMessage, IGasForm, IGasNumberField } from "@paul-igas/igas-react-components";

export function ProductoEditDialog({ open, onClose, postSubmit, data }) {
  const [errors, setErrors] = useState();

  const successMsg = useModal(false);

  const validaForm = (form) => {
    const newErrors = {};
    const limit = parseFloat(data.inventarioInicial) + parseFloat(form.entradas) - parseFloat(form.devolucion);

    newErrors.entradas = form.entradas.toString() === "" ? "'Entradas' es requerido." : "";
    newErrors.devolucion = form.devolucion.toString() === "" ? "'Devolución' es requerido." : "";
    newErrors.inventarioFinal = form.inventarioFinal.toString() === "" ? "'Inventario Final' es requerido." : "";

    if (newErrors.inventarioFinal === "") {
      newErrors.inventarioFinal =
        parseFloat(form.inventarioFinal) > limit ? `'Inventario Final' debe ser menor o igual a ${limit}` : "";
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((x) => x === "");
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
    }
  };

  const handleEdit = (prod) => {
    if (validaForm(prod)) {
      const entradas = parseFloat(prod.entradas);
      const devolucion = parseFloat(prod.devolucion);
      const inventarioFinal = parseFloat(prod.inventarioFinal);
      const venta = data.inventarioInicial + entradas - devolucion - inventarioFinal;

      const newData = {
        ...data,
        entradas,
        devolucion,
        inventarioFinal,
        venta,
        importe: venta * data.precioProducto,
      };
      onClose();
      successMsg.open();
      postSubmit(newData);
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>Editar inventario</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{data?.descripcion}</DialogContentText>
          <br />
          <IGasForm id='producto-edit-dlg' onSubmit={handleEdit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IGasNumberField
                  autoFocus
                  label='Entradas'
                  name='entradas'
                  defaultValue={data?.entradas}
                  error={errors?.entradas}
                  maxLength={5}
                />
              </Grid>

              <Grid item xs={12}>
                <IGasNumberField
                  label='Devolución'
                  name='devolucion'
                  defaultValue={data?.devolucion}
                  error={errors?.devolucion}
                  maxLength={5}
                />
              </Grid>

              <Grid item xs={12}>
                <IGasNumberField
                  label='Inventario Final'
                  name='inventarioFinal'
                  defaultValue={data?.inventarioFinal}
                  error={errors?.inventarioFinal}
                  maxLength={5}
                />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={false} type='submit' form='producto-edit-dlg'>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
    </>
  );
}
