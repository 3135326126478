import { cloneElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { UseTurnoContext } from "../../../../contexts";
import { useNavigate } from "react-router-dom";
import { setTurnoActual } from "../../../../store/operaciones/liquidaciones";
import { hasDispatchError } from "../../../../api/services/service";
import { showInWindow } from "../../../helpers/documents";
import { exportResumenTurno } from "../../../../store/operaciones/turnos";
import { DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { clearErrors } from "../../../../store/operaciones/turnos";
import { useSelector } from "react-redux";

export function OptionsPopup({ turno, trigger, deletedDisabled }) {
  const listPopup = <ListPopup turno={turno} deletedDisabled={deletedDisabled} />;

  const triggerWithList = cloneElement(trigger, { popperMenuList: listPopup });

  return <>{triggerWithList}</>;
}

function ListPopup({ turno, onClose, deletedDisabled }) {
  const { modalOpen, modalClose, modalDelete, setTurno, modalExport } = UseTurnoContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);
  const { error } = useSelector((state) => state.turnos);

  const handleError = () => {
    if (Boolean(error?.status)) {
      error?.status === 409 && warningMsg.open();
      error?.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
  };

  useEffect(handleError, [error]);

  function handleOpen() {
    dispatch(setTurno(turno));
    modalOpen.open();
    onClose();
  }

  function handleClose() {
    dispatch(setTurno(turno));
    modalClose.open();
    onClose();
  }

  function handleDelete() {
    dispatch(setTurno(turno));
    modalDelete.open();
    onClose();
  }

  function handleLiquidaciones() {
    dispatch(setTurnoActual(turno));
    navigate("/operaciones/liquidaciones/detalle");
  }

  function handleResumenDelTurno() {
    onClose();
    modalExport.open();
    dispatch(exportResumenTurno(turno.id))
      .then(hasDispatchError)
      .then(showInWindow)
      .then(modalExport.close)
      .catch(modalExport.close);
  }

  return (
    <>
      <List dense>
        {turno.estado && (
          <ListItemButton onClick={handleClose}>
            <ListItemText primary='Cerrar turno' />
          </ListItemButton>
        )}
        {!turno.estado && (
          <ListItemButton onClick={handleOpen}>
            <ListItemText primary='Abrir turno' />
          </ListItemButton>
        )}
        {turno.estado && (
          <ListItemButton onClick={handleLiquidaciones}>
            <ListItemText primary='Liquidaciones' />
          </ListItemButton>
        )}
        <ListItemButton onClick={handleResumenDelTurno}>
          <ListItemText primary='Resumen del turno' />
        </ListItemButton>
        <ListItemButton onClick={handleDelete} disabled={deletedDisabled}>
          <ListItemText primary='Eliminar turno' />
        </ListItemButton>
      </List>
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}
