import { Typography } from "@mui/material";
import { boldText } from "../helpers/utils";

export const InfoTitle = ({ variant = "caption", color = "InactiveCaptionText", caption, bold = false }) => {
  return (
    <Typography variant={variant} color={color} style={{ textAlign: variant === "h3" ? "right" : "left" }}>
      {bold ? boldText(caption) : caption}
    </Typography>
  );
};
