import { useModal, formatDateTimeMX } from "@paul-igas/igas-react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Grid,
  Alert
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { putOpen } from "../../../../store/operaciones/turnos";
import { hasDispatchError } from "../../../../api/services/service";

export function OpenDialog({ open, onClose, postSubmit, lastTurno }) {
  const dispatch = useDispatch();
  const { loading, turno } = useSelector((state) => state.turnos);
  const successMsg = useModal(false);

  const onSubmit = () => {
    dispatch(putOpen({ id: turno.id }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  if (!Date.parse(turno.fecha)) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        fullWidth
        maxWidth='xs'
        scroll='paper'
      >
        {loading.setAbrir && <LinearProgress />}
        <DialogTitle>Abrir turno</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            ¿Desea abrir turno {turno?.noTurno} del día {formatDateTimeMX(turno?.fecha)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.setAbrir}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.setAbrir} onClick={onSubmit}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text="Se abrió correctamente."
      />
    </>
  );
}
