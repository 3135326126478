import { Alert, Grid } from "@mui/material";

export const AlertaLecturas=()=>{
    return (
        <Grid container>
          <Grid item xs={12}>
            <Alert severity='info'>No se obtuvieron lecturas del dispensario, por lo tanto no se puede continuar.</Alert>
          </Grid>
        </Grid>
      );
}

export default AlertaLecturas;