import { Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hasDispatchError } from "../../../api/services/service";
import { CancelDialog, InfoPanel, StepperManager } from "../../../components/operaciones/liquidacion";
import {
  PagosStep,
  ProductosStep,
  ResumenStep,
  CombustibleStep,
} from "../../../components/operaciones/liquidacion/steps";
import {
  getIslas,
  preview,
  select,
  update,
  setJarreoConsignacionModificados,
  setLecturaProductoModificado,
  setProductoModificado,
  clearErrors,
} from "../../../store/operaciones/liquidaciones";
import { DangerMessage, LoadingPanel, WarningMessage } from "@paul-igas/igas-react-components";
import { showInWindow } from "../../../components/helpers/documents";
import { GetConfiguration } from "../../../components/helpers/variablesEstacion";
import { getUltimo } from "../../../store/operaciones/turnos";
import { useWidth } from "../../../hooks";

const steps = [
  { text: "Combustibles", textMobile: "Vtas Comb" },
  { text: "Productos diversos", textMobile: "Prod Diverso" },
  { text: "Pagos", textMobile: "Pagos" },
  { text: "Resumen", textMobile: "Resumen" },
];

export function EditarLiquidacion() {
  const data = useLiquidacion();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.liquidaciones);

  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);
  const cancelarDlg = useModal(false);

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  if (data.cargando) return <LoadingPanel title='Obteniendo liquidación...' size={100}></LoadingPanel>;

  if (!data.cargando && data.folio <= 0) return null;

  return (
    <>
      {!data.saving && (
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={12} md={7} lg={9}>
            <Typography variant='h5' paragraph>
              Editar liquidación {zeroPad(data.folio, 6)}
            </Typography>
          </Grid>

          <Grid item xs>
            <StepperManager
              disableBackButton={data.step === 2 ? true : data.disableBtns}
              disableNextButton={data.validaBotonSiguiente()}
              onCancel={cancelarDlg.open}
              onReturn={data.anteriorStep}
              onContinue={data.siguienteStep}
            />
          </Grid>
        </Grid>
      )}

      <CancelDialog open={cancelarDlg.isShowing} onClose={cancelarDlg.close} isEdit={true} />

      <WarningMessage
        open={data.limiteVentasExcedidoMsg.isShowing}
        onClose={data.limiteVentasExcedidoMsg.close}
        text='Existen ventas que exceden el límite máximo permitido.'
      />

      {data.saving ? (
        <LoadingPanel title='Guardando liquidación' size={100} />
      ) : (
        <>
          <Paper sx={{ mt: 1, mb: 1 }}>
            <Stepper activeStep={data.step - 2} style={{ padding: 16 }} alternativeLabel>
              {steps.map((label) => (
                <Step key={label.text}>
                  <StepLabel>{data.screen === "xs" || data.screen === "sm" ? label.textMobile : label.text}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
          {data.step < 5 && <InfoPanel data={data} />}
          {data.turnoCargado && data.step === 2 && <CombustibleStep data={data} />}
          {data.turnoCargado && data.step === 3 && <ProductosStep data={data} />}
          {data.turnoCargado && data.step === 4 && <PagosStep data={data} />}
          {data.turnoCargado && data.step === 5 && <ResumenStep data={data} />}
        </>
      )}

      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}

export default EditarLiquidacion;

const useLiquidacion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screen = useWidth();
  const { loading, selectedLiq, islas, lecturaProductoModificado, jarreoConsignacionModificados, productoModificado } =
    useSelector((state) => state.liquidaciones);
  const [liquidacion, setLiquidacion] = useState({});
  const [step, setStep] = useState(2);
  const [turno, setTurno] = useState(null);
  const [lastTurno, setLastTurno] = useState({});
  const [isla, setIsla] = useState(null);
  const [despachador, setDespachador] = useState(null);
  const [disableBtns, setDisableBtns] = useState(true);
  const [saving, setSaving] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [pagosAceites, setPagosAceites] = useState(0.0);
  const limiteVentasExcedidoMsg = useModal(false);
  const LIMITEMAXPERMITIDO = GetConfiguration(4)?.valor;
  const totales = {
    combustibles: isla?.lecturas?.reduce((a, b) => a + b.importe, 0) ?? 0,
    productos: isla?.productos?.reduce((a, b) => a + b.importe, 0) ?? 0,
    pagos: isla?.pagos?.reduce((a, b) => a + b.importe, 0) ?? 0,
    denominaciones: isla?.denominaciones?.reduce((a, b) => a + b.importe, 0) ?? 0,
  };

  const diferencia = round(round(totales.combustibles, 2) + round(totales.productos, 2) - round(totales.pagos, 2), 2);
  const diferenciaAceites = round(round(totales.productos, 2) - round(pagosAceites, 2), 2);

  const modeloLiquidacion = {
    id: liquidacion?.id,
    idTurno: turno?.id,
    noTurno: turno?.noTurno,
    fechaHora: turno?.fecha,
    idIsla: isla?.id,
    noIsla: isla?.isla,
    folio: liquidacion?.folio,
    idDespachador: despachador?.id,
    nombreDespachador: despachador?.nombreCompleto,
    totalCombustible: totales.combustibles,
    totalDiversos: totales.productos,
    totalGeneral: totales.productos + totales.combustibles,
    totalPagado: totales.pagos,
    lecturasCombustibles: isla?.lecturas,
    productosDiversos: isla?.productos,
    tiposPagos: isla?.pagos,
    denominaciones: isla?.denominaciones,
    lecturaModificada: lecturaProductoModificado,
    jarreoConsignacionModificado: jarreoConsignacionModificados,
    esGlobal: liquidacion?.esGlobal,
  };

  const calculateData = () => {
    //Inicializa despachador
    if ((despachador?.id ?? "") === "")
      setDespachador({ id: liquidacion.idDespachador, nombreCompleto: liquidacion.nombreDespachador });
    //Inicializa isla
    if ((isla?.id ?? 0) === 0) {
      let _isla = islas?.find((e) => e.id === liquidacion.idIsla);
      setIsla({
        ..._isla,
        lecturas: liquidacion?.lecturasCombustibles?.map((l) => ({ ...l })),
        productos: liquidacion?.productosDiversos,
        pagos: liquidacion?.tiposPagos,
        denominaciones: liquidacion?.denominaciones,
        liquidada: false,
      });
    }
  };

  function calculaVolumen(l) {
    return (
      l.lecturaFinal -
      l.lecturaInicial -
      l.devolucion -
      l.consignacion -
      l.diferenciaLecturas -
      (l.diferenciaLecturas2 ?? 0)
    );
  }

  const asignaLiquidacion = ({ payload }) => {
    let combustibles = payload.lecturasCombustibles;
    /*Se hace un recalculado de la liquidación actual ya que,
      al editar la liquidación sólo traía valores estáticos 
      y choca con el proceso de modif. de lecturas finales*/
    let nuevaData = combustibles?.map((l) => ({
      ...l,
      diferencia: calculaVolumen(l),
      importe: round(calculaVolumen(l) * l.precioCombustible, 2),
    }));
    setLiquidacion({ ...payload, lecturasCombustibles: nuevaData });
  };

  const validaBotonSiguiente = (_) => {
    if (disableBtns) return true;
    if (step > 4) return true;
    if (step === 4 && (Math.abs(diferencia) > 0 || Math.abs(diferenciaAceites) > 0)) return true;

    return false;
  };

  const siguienteStep = (_) => {
    /*Validación de límite de venta permitido*/
    const acumDiff = isla?.lecturas?.filter(
      (item) => item.diferencia > LIMITEMAXPERMITIDO && item.lecturaInicial > item.lecturaFinal
    );
    if (step === 2 && acumDiff?.length > 0) {
      limiteVentasExcedidoMsg.open();
      return;
    }
    setStep((step) => step + 1);
  };

  const asignaLastTurno = ({ payload }) => {
    setLastTurno(payload);
  };

  const initStep = () => {
    if ((liquidacion?.id ?? 0) <= 0) {
      dispatch(getIslas())
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(select({ id: selectedLiq.id }))
        .then(hasDispatchError)
        .then(asignaLiquidacion)
        .catch(() => {});
      dispatch(getUltimo())
        .then(hasDispatchError)
        .then(asignaLastTurno)
        .catch(() => {});
      dispatch(setLecturaProductoModificado(false));
      dispatch(setJarreoConsignacionModificados(false));
      dispatch(setProductoModificado(false));
    }
  };

  const onSave = async () => {
    dispatch(update(modeloLiquidacion))
      .then(hasDispatchError)
      .then(() => navigate("/operaciones/liquidaciones/detalle"))
      .catch(() => {});
  };

  const onPreview = async () => {
    dispatch(preview(modeloLiquidacion))
      .then(hasDispatchError)
      .then(showInWindow)
      .catch(() => {});
  };

  useEffect(initStep, []);
  useEffect(calculateData, [liquidacion]);
  useEffect(() => {
    loading.update ? setSaving(true) : setSaving(false);
    loading.preview ? setProcessing(true) : setProcessing(false);
  }, [loading]);

  return {
    cargando: loading.getIslas || loading.select,
    folio: liquidacion?.folio ?? 0,
    step,
    turno,
    turnoCargado: (turno?.id ?? 0) > 0,
    isla: isla ?? {},
    despachador,
    disableBtns,
    totales,
    saving,
    diferencia,
    diferenciaAceites,
    lecturaProductoModificado: lecturaProductoModificado,
    processing,
    limiteVentasExcedidoMsg,
    esGlobal: liquidacion?.esGlobal ?? false,
    esUltimoTurno: Object.keys(lastTurno).length !== 0 && lastTurno?.id !== turno?.id ? false : true,
    productoModificado: productoModificado,
    screen,
    asignaTurno: setTurno,
    pagosAceites: setPagosAceites,
    siguienteStep,
    anteriorStep: () => setStep((step) => step - 1),
    desactivaStepBtn: () => setDisableBtns(true),
    activaStepBtn: () => setDisableBtns(false),
    asignaIsla: (isla) => setIsla(isla),
    validaBotonSiguiente,
    onSave,
    onPreview,
  };
};

const round = (num, decimal) => {
  return +(Math.round(num + "e+" + decimal) + "e-" + decimal);
};
