import api from "../service";

const _controller = "ventasdiversos/servicios";

export async function GetVentasDiversos(params) {
  const res = await api.get(`${_controller}/SelectByIslaTurno`, { params });
  return res.data;
}

export async function GetVentasDiversosGlobal(params) {
  const res = await api.get(`${_controller}/SelectByGlobalTurno`, { params });
  return res.data;
}

export default {
  GetVentasDiversos,
  GetVentasDiversosGlobal
};
