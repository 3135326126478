import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { Skeleton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../api/services/service";
import { clearErrors, getVentasCombustibles } from "../../store/consultas/dashboard";
import { formatDateTimeUS, useModal } from "@paul-igas/igas-react-hooks";
import { Parametros, daysInMonth, formatNumber } from "./configuracion";

const _today = new Date();
function obtenerFechas(periodo) {
  const _year = _today.getFullYear();
  const _month = _today.getMonth();
  const fechaIni = formatDateTimeUS(new Date(_year, periodo === Parametros.anio ? 0 : _month, 1));
  const fechaFin = formatDateTimeUS(new Date(_year, _month, daysInMonth(_year, _month)));
  return { fechaIni, fechaFin };
}

export const VentasCombustibles = ({ unidad, periodo }) => {
  const dispatch = useDispatch();
  const { fechaIni, fechaFin } = obtenerFechas(periodo);
  const { loading, ventasCombustibles = [] } = useSelector((state) => state.dashboard);
  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);
  const loadingDialog = loading.getVentasCombustibles;

  const initialize = () => {
    handleCloseError();
    dispatch(getVentasCombustibles({ fechaIni, fechaFin }))
      .then(hasDispatchError)
      .catch(() => {});
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const sumTotales = (ventasCombustibles, key) => {
    return ventasCombustibles.reduce((acc, venta) => acc + (venta[key] || 0), 0);
  };

  useEffect(initialize, [periodo]);

  const ventas = Array.isArray(ventasCombustibles) ? ventasCombustibles : [];

  const total = sumTotales(ventas, unidad === Parametros.importe ? "totalImporte" : "totalDiferencia");

  const combustiblesNames = ventas.map((item) => item.combustible || "Desconocido");
  const data =
    unidad === Parametros.importe
      ? ventas.map((venta) => venta.totalImporte || 0)
      : ventas.map((venta) => venta.totalDiferencia || 0);

  return (
    <Box sx={{ height: "100%" }}>
      {loadingDialog ? (
        <>
          <Skeleton animation='pulse' width='100%' height='100%' />
        </>
      ) : (
        <>
          {ventas.length > 0 ? <Titulo periodo={periodo} unidad={unidad} total={total} loading={loadingDialog} /> : ""}
          <Box sx={{ width: "100%", height: "100%" }}>
            <BarChart
              series={[
                {
                  data: data,
                  valueFormatter:
                    unidad === Parametros.importe
                      ? (v) =>
                          new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                            unitDisplay: "long",
                          }).format(v)
                      : (v) =>
                          new Intl.NumberFormat("es-MX", {
                            style: "unit",
                            unit: "liter",
                            unitDisplay: "long",
                          }).format(v),
                  style: {
                    bar: { strokeWidth: 1 },
                    point: { r: 3 },
                  },
                },
              ]}
              xAxis={[
                {
                  scaleType: "band",
                  data: combustiblesNames,
                  colorMap: {
                    type: "ordinal",
                    colors: ["#4CAF50", "#F44336", "#000000"],
                  },
                  tickLabelStyle: {
                    fontSize: 10,
                  },
                },
              ]}
              yAxis={[{ valueFormatter: (value) => formatNumber(value) }]}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const Titulo = ({ periodo, unidad, total, loading }) => {
  if (loading) return <Skeleton />;
  return (
    <Typography gutterBottom>
      {periodo === Parametros.anio
        ? unidad === Parametros.importe
          ? ` Venta de combustibles por año $${formatNumber(total)}`
          : ` Venta de combustibles por año ${formatNumber(total)} (lts)`
        : unidad === Parametros.importe
        ? ` Venta de combustibles por mes $${formatNumber(total)}`
        : ` Venta de combustibles por mes ${formatNumber(total)} (lts)`}
    </Typography>
  );
};
