import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { boldText } from "../../../helpers/utils";

export function CancelDialog({ open, onClose, isEdit = false }) {
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleCancel = () => {
    onClose();
    navigate("/operaciones/liquidaciones/detalle");
  };

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
      <DialogTitle>Cancelar {isEdit ? "edición" : "registro"} de liquidación</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Al interrumpir {isEdit ? "la edición" : "el registro"} de liquidación se perderá el progreso realizado.
          {<br />}
          {<br />} {boldText("¿Proceder con la cancelación?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' disableElevation onClick={handleClose}>
          No
        </Button>
        <Button color='secondary' disableElevation onClick={handleCancel}>
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}
