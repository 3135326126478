import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { useModal, monedaMX } from "@paul-igas/igas-react-hooks";
import { SuccessMessage, IGasForm, IGasNumberField } from "@paul-igas/igas-react-components";

export function DenomEditDialog({ open, onClose, postSubmit, data }) {
  const [errors, setErrors] = useState();

  const successMsg = useModal(false);

  const validaForm = (form) => {
    const newErrors = {};

    newErrors.cantidad = form.cantidad.toString() === "" ? "'Cantidad' es requerida." : "";

    setErrors(newErrors);

    return Object.values(newErrors).every((x) => x === "");
  };

  const handleSubmit = (denom) => {
    if (validaForm(denom)) {
      let cantidad = parseFloat(denom.cantidad);
      let valor = parseFloat(data.valor);
      let importe = cantidad * valor;
      let newData = { ...data, cantidad, valor, importe };
      onClose();
      successMsg.open();
      postSubmit(newData);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>Editar cantidad</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Valor: {monedaMX.format(data?.valor ?? 0)}</DialogContentText>
          <br />
          <IGasForm id='denom-edit-dlg' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IGasNumberField
                  autoFocus
                  label='Cantidad'
                  name='cantidad'
                  defaultValue={data?.cantidad ?? 0}
                  error={errors?.cantidad}
                  maxLength={2}
                />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={false} type='submit' form='denom-edit-dlg'>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
    </>
  );
}

export default DenomEditDialog;