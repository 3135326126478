import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DangerMessage,
  ExportDialog,
  IGasDateField,
  IGasForm,
  IGasSelectField,
  ReportDialog,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { formatoReporte } from "../../utils/enums";
import { Grid, MenuItem, Skeleton } from "@mui/material";
import { formatDateTimeUS, useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../api/services/service";
import { getIslas } from "../../store/operaciones/liquidaciones";
import { clearErrors, resumenDeVentasPorDespachador, setNombreReporte } from "../../store/reportes/reportesLiq";
import { esFechaValida, rangoFechasValido } from "../../utils/functions";
import { downloadFile, showInWindow } from "../../components/helpers/documents";

const NOW = new Date();

const porCombustibles = [
  {
    id: 1,
    value: true,
    label: "Combustibles",
  },
  {
    id: 2,
    value: false,
    label: "Aceites",
  },
];

const turnos = [
  { id: 1, descripcion: "Turno 1" },
  { id: 2, descripcion: "Turno 2" },
  { id: 3, descripcion: "Turno 3" },
  { id: 4, descripcion: "Turno 4" },
];

const formatoFecha = /^\d{4}-\d{2}-\d{2}$/;

export const ConfigReporteVentasDespachador = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.reportesLiq);
  const liqStore = useSelector((state) => state.liquidaciones);
  const navigator = useNavigate();

  const [open, setOpen] = useState(true);
  const [formato, setFormato] = useState(formatoReporte.PDF);
  const [errorMessage, setErrorMessage] = useState("");

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  const modalExport = useModal(false);

  const handleSubmit = (data) => {
    if (!esFechaValida(data.fechaIni)) {
      setErrorMessage((e) => ({
        ...e,
        fechaIni: "Fecha Inicial no tiene un formato de fecha válido.",
      }));
      return;
    }
    if (!esFechaValida(data.fechaFin)) {
      setErrorMessage((e) => ({
        ...e,
        fechaFin: "Fecha Final no tiene un formato de fecha válido.",
      }));
      return;
    }
    if (rangoFechasValido(data.fechaIni, data.fechaFin)) {
      setErrorMessage((e) => ({
        ...e,
        fechaIni: "'Fecha Inicial' no debe ser mayor a 'Fecha final.'",
      }));
      return;
    }

    modalExport.open();
    dispatch(resumenDeVentasPorDespachador({ ...data, formato: formato }))
      .then(hasDispatchError)
      .then(formato === formatoReporte.XLS ? downloadFile : showInWindow)
      .then(modalExport.close)
      .catch(modalExport.close);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    navigator("/");
  };

  const init = () => {
    dispatch(getIslas())
      .then(hasDispatchError)
      .catch(() => {});

    dispatch(setNombreReporte("Resumen de Ventas por Despachador"));
  };

  const handleCloseError = () => {
    setErrorMessage("");
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status >= 500 && dangerMsg.open();
    }
  };

  useEffect(init, []);
  useEffect(handleError, [error]);

  return (
    <>
      <ReportDialog
        open={open}
        onClose={handleClose}
        title='Resumen de Ventas por Despachador'
        maxWidth='xs'
        onsetFormat={setFormato}
      >
        <IGasForm id='resumen-por-despachador-dlg' onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <IGasDateField
                autoFocus
                label='De la fecha:'
                name='fechaIni'
                defaultValue={formatDateTimeUS(NOW)}
                error={errorMessage?.fechaIni}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <IGasDateField
                label='A la fecha:'
                name='fechaFin'
                defaultValue={formatDateTimeUS(NOW)}
                error={errorMessage?.fechaFin}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <IGasSelectField label='Tipo de Producto' name='porCombustible' defaultValue='true'>
                {porCombustibles.map((i) => (
                  <MenuItem key={i.id} value={i.value}>
                    {i.label}
                  </MenuItem>
                ))}
              </IGasSelectField>
            </Grid>

            <Grid item xs={12} sm={12}>
              {liqStore.loading.getIslas && <Skeleton />}
              {!liqStore.loading.getIslas && (
                <IGasSelectField label='Isla' name='noIsla' defaultValue='0'>
                  <MenuItem value='0'>Todas las Islas</MenuItem>
                  {liqStore.islas.map((i) => (
                    <MenuItem key={i.id} value={i.isla}>
                      {`Isla ${i.isla}`}
                    </MenuItem>
                  ))}
                </IGasSelectField>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <IGasSelectField label='Turno' name='noTurno' defaultValue='0'>
                <MenuItem value='0'>Todos los Turnos</MenuItem>
                {turnos.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {`Turno ${i.id}`}
                  </MenuItem>
                ))}
              </IGasSelectField>
            </Grid>
          </Grid>
        </IGasForm>
      </ReportDialog>

      <ExportDialog open={modalExport.isShowing} onClose={modalExport.close} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
};

export default ConfigReporteVentasDespachador;
