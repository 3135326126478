import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { formatDateTimeMX, useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { remove } from "../../../../store/operaciones/turnos";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, turno } = useSelector((state) => state.turnos);

  const successMsg = useModal(false);

  const onSubmit = () => {
    dispatch(remove({ id: turno.id }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  if (!Date.parse(turno.fecha)) return null;

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='xs' scroll='paper'>
        {loading.delete && <LinearProgress />}

        <DialogTitle>Eliminar turno</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            ¿Desea eliminar turno {boldText(zeroPad(turno?.noTurno, 2))} del día{" "}
            {boldText(formatDateTimeMX(turno?.fecha))}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.delete} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.delete} onClick={onSubmit}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
    </>
  );
}
