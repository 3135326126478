import { getStation } from "../../authService";
import api from "../service";

const _controller = "dispensario/servicios";

export async function GetIslas() {
  let params = { idEstacion: await getStation() };
  const res = await api.get(`${_controller}/islas`, { params });
  return res.data;
}

export default {
  GetIslas,
};
