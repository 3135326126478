import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { remove } from "../../../../store/operaciones/liquidaciones";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, selectedLiq } = useSelector((state) => state.liquidaciones);

  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = () => {
    dispatch(remove({ id: selectedLiq.id }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>Eliminar liquidación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea eliminar la liquidación con folio {boldText(zeroPad(selectedLiq.folio, 6))}?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' disabled={loading.delete} onClick={onClose}>
            Cancelar
          </Button>
          <Button color='secondary' disabled={loading.delete} onClick={onSubmit}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
    </>
  );
}

export default DeleteDialog;
