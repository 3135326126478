import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress } from "@mui/material";
import { SuccessMessage, SimpleTable, ColumnType, ColumnAlign, ColumnPriority } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { getIslasEnCeros, liquidarIslasEnCeros } from "../../../../store/operaciones/liquidaciones";

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Isla",
      name: "noIsla",
      colType: ColumnType.DataKey,
      colAlign: ColumnAlign.Center,
      digitos: 3,
    },
    { text: "Despachador", name: "nombreDespachador", colPriority: ColumnPriority.Low },
    {
      text: "Combustible",
      name: "totalCombustible",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Diversos",
      name: "totalDiversos",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
      colPriority: ColumnPriority.Normal,
    },
  ],
};

export function IslasEnCerosDialog({ open, onClose, postSubmit, turno }) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.liquidaciones);
  const [islas, setIslas] = useState();
  const [idIslaArray, setidIslaArray] = useState();
  const [noIslaArray, setnoIslaArray] = useState();
  const successMsg = useModal(false);
  const isLoading = loading.getIslasEnCeros || loading.insertLiqEnCeros;

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = () => {
    dispatch(
      liquidarIslasEnCeros({
        idIsla: idIslaArray,
        noIsla: noIslaArray,
        fechaTurno: turno.fecha,
        idTurno: turno.id,
        noTurno: turno.noTurno,
      })
    )
      .then(hasDispatchError)
      .then(onClose)
      .then(postSubmit)
      .then(successMsg.open)
      .catch(() => {});
  };

  const handleIslas = () => {
    setIslas([]);
    if (open) {
      dispatch(
        getIslasEnCeros({
          fechaTurno: turno.fecha,
          idTurno: turno.id,
          noTurno: turno.noTurno,
        })
      )
        .then(hasDispatchError)
        .then(asignaIslas)
        .catch(onClose);
    }
  };

  const asignaIslas = ({ payload }) => {
    /*Se desestructura el arreglo para mostrar datos 
    en pantalla y separar los id's y #s de islas para
    petición de liquidar islas en ceros*/
    const tableArray = Array.from(
      payload
        .reduce((map, obj) => {
          const key = JSON.stringify([obj?.idIsla, obj?.isla, obj?.despachador]);
          if (!map.has(key)) {
            const newObj = {
              ...obj,
              totalCombustible: 0,
              totalDiversos: 0,
            };
            map.set(key, newObj);
          }
          return map;
        }, new Map())
        .values()
    );
    setIslas(tableArray);
    const arrayIdIsla = tableArray.map((obj) => obj.idIsla);
    setidIslaArray(arrayIdIsla);
    const arrayNoIsla = tableArray.map((obj) => obj.noIsla);
    setnoIslaArray(arrayNoIsla);
  };

  useEffect(handleIslas, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {isLoading && <LinearProgress />}

        <DialogTitle>Liquidar islas sin movimientos</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity='info'>
                El listado indica las islas que no tuvieron movimientos en el turno.
                <br />
                Haga clic en "Aceptar" para generar su liquidación.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <SimpleTable
                table={TABLE_DEFAULT}
                data={islas}
                loading={loading.getIslasEnCeros}
                showPagination={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation onClick={handleClose} disabled={isLoading}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            onClick={onSubmit}
            disabled={isLoading || (islas?.length ?? 0) <= 0}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text='Se liquidaron las islas correctamente.'
      />
    </>
  );
}
