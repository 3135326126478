import api from "../service";

const _controller = "preliquidacion/servicios";

export async function GetAll() {
  const res = await api.get(`${_controller}/selectAll`);
  return res.data;
}

export async function Get(params) {
  const res = await api.get(`${_controller}`, { params });
  return res.data;
}

export async function GetByIslaTurno(params) {
  const res = await api.get(`${_controller}/SelectByIslaTurno`, { params });
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(`${_controller}`, params);
  return res.data;
}

export default {
  GetAll,
  Get,
  GetByIslaTurno,
  Insert
};
