import api from "../service";

const _impresion = "reportesTurnoLiq/reportes";

export async function ExportResumenTurno(params) {
  const res = await api.post(`${_impresion}/ResumenTurnoPDF?idTurno=${params}`, null, { responseType: "arraybuffer" });
  return res.data;
}

export default {
  ExportResumenTurno,
};
