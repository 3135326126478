import { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { getVentasDiversos, setProductoModificado } from "../../../../store/operaciones/liquidaciones";
import { ColumnAlign, ColumnType, LoadingPanel, SimpleTable } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { OptionTableRowProd, ProductoDetailDialog, ProductoEditDialog, TableProductosMobile } from "../productosStep";

const TABLE_DEFAULT = {
  columns: [
    { text: "Descripción", name: "descripcion" },
    {
      text: "Inv. Inicial",
      name: "inventarioInicial",
      colType: ColumnType.Numeric,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Entradas",
      name: "entradas",
      colType: ColumnType.Numeric,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Devolución",
      name: "devolucion",
      colType: ColumnType.Numeric,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Inv. Final",
      name: "inventarioFinal",
      colType: ColumnType.Numeric,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Venta",
      name: "venta",
      colType: ColumnType.Numeric,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Precio",
      name: "precioProducto",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Importe",
      name: "importe",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "",
      name: "action",
      options: true,
      icon: true,
    },
  ],
};

const TABLE_TOTALS = [
  {
    name: "action",
    colAlign: ColumnAlign.Right,
    isLabel: true,
    total: true,
    colSpan: 5,
  },
  { name: "venta", colType: ColumnType.Decimal, colAlign: ColumnAlign.Right, total: true },
  { name: "sep1", colAlign: ColumnAlign.Center },
  { name: "importe", colType: ColumnType.Money, colAlign: ColumnAlign.Right, total: true },
];

export function ProductosStep({ data }) {
  const {
    loading,
    productos,
    producto,
    totales,
    editDialog,
    detailDialog,
    openEditDialog,
    openDetailDialog,
    onSubmitEditDialog,
  } = useProductosStep(data);

  const isMobile = data.screen === "xs" || data.screen === "sm";

  return (
    <Paper sx={{ mt: 1, mb: 1 }}>
      {isMobile && loading && <LoadingPanel title='Cargando...' />}

      {isMobile && !loading && (
        <TableProductosMobile
          productos={productos}
          openEditDialog={openEditDialog}
          openDetailDialog={openDetailDialog}
          totales={totales}
          screen={data.screen}
        />
      )}

      {!isMobile && (
        <SimpleTable
          table={TABLE_DEFAULT}
          data={productos}
          showPagination={false}
          loading={loading}
          rowOptions={<OptionTableRowProd onEdit={openEditDialog} />}
          rowTotals={{ columns: TABLE_TOTALS, data: totales }}
        />
      )}

      <ProductoDetailDialog open={detailDialog.isShowing} onClose={detailDialog.close} data={producto} />

      <ProductoEditDialog
        open={editDialog.isShowing}
        onClose={editDialog.close}
        postSubmit={onSubmitEditDialog}
        data={producto}
      />
    </Paper>
  );
}

const useProductosStep = (data) => {
  const dispatch = useDispatch();
  const { loading, productoModificado } = useSelector((state) => state.liquidaciones);
  const { turno, isla, desactivaStepBtn, activaStepBtn, asignaIsla } = data;
  const [producto, setProducto] = useState({});
  const editDialog = useModal(false);
  const detailDialog = useModal(false);

  const openEditDialog = (row) => {
    setProducto(row);
    editDialog.open();
  };

  const openDetailDialog = (row) => {
    setProducto(row);
    detailDialog.open();
  };

  const onSubmitEditDialog = (newRow) => {
    const index = isla.productos.findIndex((p) => p.id === newRow.id);
    let tmp = isla.productos.slice();
    tmp[index] = newRow;
    asignaIsla({ ...isla, productos: tmp });
    /*Se valida que no sea el último turno para mostrar
      mensaje de advertencia de descuadre*/
    if (!data.esUltimoTurno) {
      dispatch(setProductoModificado(true));
    }
  };
  const asignaVentasProd = ({ payload }) => {
    asignaIsla({ ...isla, productos: payload });
  };

  const initStep = () => {
    if ((isla.productos?.length ?? 0) <= 0) {
      dispatch(getVentasDiversos({ idIsla: isla.id, fechaTurno: turno.fecha, noTurno: turno.noTurno }))
        .then(hasDispatchError)
        .then(asignaVentasProd)
        .catch(() => {});
    }
  };

  useEffect(initStep, []);
  useEffect(() => {
    loading.getVentasDiversos ? desactivaStepBtn() : activaStepBtn();
  }, [loading]);

  return {
    loading: loading.getVentasDiversos,
    productos: isla.productos ?? [],
    producto,
    totales: [
      {
        venta: isla.productos?.reduce((a, b) => a + b.venta, 0),
        sep1: "-",
        importe: isla.productos?.reduce((a, b) => a + b.importe, 0),
      },
    ],
    productoModificado: productoModificado,
    editDialog,
    detailDialog,
    openEditDialog,
    openDetailDialog,
    onSubmitEditDialog,
  };
};
