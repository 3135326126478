import { useState, useEffect } from "react";
import { Paper, Button } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from "@mui/material";
import { decimalVal, monedaMX, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { getLecturas } from "../../../../store/operaciones/preliquidaciones";
import { hasDispatchError } from "../../../../api/services/service";
import { styled } from "@mui/system";
import { LoadingPanel } from "../loadingPanel";

const CellHead = styled(TableCell)(() => ({ fontWeight: "bold" }));

export function CombustibleStep({ data }) {
  const { loading, lecturas, totales } = useCombustibleStep(data);

  return (
    <Paper>
      {loading && <LoadingPanel title='Cargando...' />}
      {!loading && (
        <TableContainer>
          <Table size='small'>
            <TableHeadCombustible />
            <TableBody>
              {lecturas.map((lectura) => (
                <RowCombustible key={lectura.id} rowData={lectura} />
              ))}
            </TableBody>
            <TotalsCombustible totalsRow={totales} />
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

const TableHeadCombustible = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <CellHead align='right'>Disp.</CellHead>
        <CellHead align='right'>Mang.</CellHead>
        <CellHead>Combustible</CellHead>
        <CellHead align='right'>Lect. Inicial</CellHead>
        <CellHead align='right'>Lect. Final</CellHead>
        <CellHead align='right'>Vol. Jarreo</CellHead>
        <CellHead align='right'>Volumen</CellHead>
        <CellHead align='right'>Precio</CellHead>
        <CellHead align='right'>Importe</CellHead>
      </TableRow>
    </TableHead>
  );
};

const RowCombustible = ({ rowData }) => {
  const [expandible, setExpandible] = useState(false);

  return (
    <>
      <TableRow>
        {!rowData.detalle && <TableCell component='th' scope='row' align='right'></TableCell>}
        {rowData.detalle && (
          <TableCell style={{ padding: 1 }} component='th' scope='row' align='right'>
            {!expandible && (
              <Button onClick={() => setExpandible(true)}>
                <ChevronRight fontSize='small' color='action' />
              </Button>
            )}
            {expandible && (
              <Button onClick={() => setExpandible(false)}>
                <ExpandMore fontSize='small' color='action' />
              </Button>
            )}
          </TableCell>
        )}
        <TableCell align='right'>{rowData.dispensario}</TableCell>
        <TableCell align='right'>{rowData.manguera}</TableCell>
        <TableCell>{rowData.combustible}</TableCell>
        <TableCell align='right'>{rowData.lecturaInicial}</TableCell>
        <TableCell align='right'>{rowData.lecturaFinal}</TableCell>
        <TableCell align='right'>{rowData.volJarreo}</TableCell>
        <TableCell align='right'>{rowData.volumen}</TableCell>
        <TableCell align='right'>{rowData.precio}</TableCell>
        <TableCell align='right'>{rowData.importe}</TableCell>
      </TableRow>
    </>
  );
};

const TotalsCombustible = ({ totalsRow }) => {
  return (
    <TableFooter style={{}}>
      <TableRow>
        <CellHead variant='head' colSpan={6} align='right'>
          Total
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.volJarreo}
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.volumen}
        </CellHead>
        <CellHead variant='head' align='right'>
          -
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.importe}
        </CellHead>
      </TableRow>
    </TableFooter>
  );
};

const useCombustibleStep = (data) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.preliquidaciones);
  const { turno, isla, desactivaStepBtn, activaStepBtn, asignaIsla } = data;

  const lecturasDisplay = function (xs, key) {
    if (!xs) return [];

    const group = xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    const groupArray = Object.values(group);

    return groupArray.map((grp, index) => ({
      id: index,
      dispensario: zeroPad(grp[0].noDispensario, 2),
      manguera: zeroPad(grp[0].noManguera, 2),
      combustible: grp[0].nombreCombustible,
      lecturaInicial: decimalVal(3).format(grp[0].lecturaInicial),
      lecturaFinal: decimalVal(3).format(grp[grp.length - 1].lecturaFinal),
      volJarreo: decimalVal(3).format(grp.reduce((a, b) => a + b.devolucion, 0)),
      volumen: decimalVal(3).format(grp.reduce((a, b) => a + b.diferencia, 0)),
      precio: grp.length === 1 ? monedaMX.format(grp[0].precioCombustible) : "-",
      importe: monedaMX.format(grp.reduce((a, b) => a + b.importe, 0)),
      detalle:
        grp.length === 1
          ? null
          : grp.map((item, index) => ({
              id: index,
              lecturaInicial: decimalVal(3).format(item.lecturaInicial),
              lecturaFinal: decimalVal(3).format(item.lecturaFinal),
              volJarreo: decimalVal(3).format(item.devolucion),
              volumen: decimalVal(3).format(item.diferencia),
              precio: monedaMX.format(item.precioCombustible),
              importe: monedaMX.format(item.importe),
            })),
    }));
  };

  const asignaLecturas = ({ payload }) => {
    asignaIsla({ ...isla, lecturas: payload });
  };

  const initStep = () => {
    if (!isla.lecturas) {
      dispatch(getLecturas({ idIsla: isla.id, fechaTurno: turno.fecha, noTurno: turno.noTurno }))
        .then(hasDispatchError)
        .then(asignaLecturas)
        .catch(() => {});
    }
  };

  useEffect(initStep, []);
  useEffect(() => {
    loading.getLecturas ? desactivaStepBtn() : activaStepBtn();
  }, [loading]);

  return {
    loading: loading.getLecturas,
    lecturas: lecturasDisplay(isla.lecturas, "idManguera"),
    totales: {
      volJarreo: decimalVal(3).format(isla.lecturas?.reduce((a, b) => a + b.devolucion, 0)),
      volumen: decimalVal(3).format(isla.lecturas?.reduce((a, b) => a + b.diferencia, 0)),
      importe: monedaMX.format(isla.lecturas?.reduce((a, b) => a + b.importe, 0)),
    },
  };
};
