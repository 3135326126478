import { cloneElement } from "react";
import { usePopper } from "@paul-igas/igas-react-hooks";
import { Button, ClickAwayListener, Paper, Popper } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

const flat = {
  root: {
    minWidth: 0,
  },
};

export function ActionCell({ popperMenuList }) {
  const menu = usePopper(false);
  const popperMenuListWithOnClose = cloneElement(popperMenuList, { onClose: menu.close });

  return (
    <>
      <Button size='small' disableElevation onClick={menu.open} sx={flat}>
        <MoreVert fontSize='small' color='action' />
      </Button>

      {Boolean(menu.anchorEl) && (
        <Popper open={menu.isShowing} anchorEl={menu.anchorEl} placement='bottom-end'>
          <ClickAwayListener onClickAway={menu.close}>
            <Paper>{popperMenuListWithOnClose}</Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}
