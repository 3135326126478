import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decimalVal, formatDateTimeUS, monedaMX, useModal } from "@paul-igas/igas-react-hooks";
import { formatNumber, getDaysInMonthUTC, getParametrosFecha, getTotalDet, MESES, Parametros } from "./configuracion";
import { clearErrors, getVentasCombustiblesDetalle } from "../../store/consultas/dashboard";
import { hasDispatchError } from "../../api/services/service";
import { GraphicTitle } from "./pieComponents";
import { LineChart } from "@mui/x-charts";
import { Skeleton } from "@mui/material";

export const VentasCombustiblesDetalle = ({ pPer, pUni }) => {
  const dispatch = useDispatch();
  const { loading, ventasCombustiblesDet, error } = useSelector((state) => state.dashboard);
  const [periodo, setPeriodo] = useState([]);

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const params = getParametrosFecha(pPer);

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const initialize = () => {
    handleCloseError();
    setPeriodo(pPer === Parametros.anio ? MESES : getDaysInMonthUTC(params.year, params.month));
    dispatch(
      getVentasCombustiblesDetalle({
        fechaIni: formatDateTimeUS(params.fechaIni),
        fechaFin: formatDateTimeUS(params.fechaFin),
        porMes: pPer === Parametros.mes,
      })
    )
      .then(hasDispatchError)
      .catch(() => {});
  };

  const total = getTotalDet(pUni, ventasCombustiblesDet);

  useEffect(initialize, [pPer]);
  useEffect(handleError, [error]);

  return (
    <>
      <GraphicTitle
        loading={loading.getVentasCombustiblesDetalle}
        caption={`Ventas de combustibles por ${pPer === Parametros.anio ? "año" : "mes"} ${
          pUni === Parametros.importe ? "$" : ""
        }${formatNumber(total)} ${pUni === Parametros.litros ? "(lts)" : ""}`}
      />

      {loading.getVentasCombustiblesDetalle && <Skeleton animation='pulse' width='100%' height='100%' />}

      {!loading.getVentasCombustiblesDetalle && (
        <LineChart
          slotProps={{
            noDataOverlay: { message: "" },
          }}
          series={ventasCombustiblesDet.map((key, index) => ({
            label: key.nombre,
            data: pUni === Parametros.importe ? key.importe : key.volumen,
            color: key.color,
            valueFormatter:
              pUni === Parametros.importe ? (v) => monedaMX.format(v) : (v) => `${decimalVal(2).format(v)} litros`,
          }))}
          xAxis={[
            {
              scaleType: "point",
              data: periodo,
            },
          ]}
          yAxis={[{ valueFormatter: (value) => formatNumber(value) }]}
        />
      )}
    </>
  );
};

export default VentasCombustiblesDetalle;
