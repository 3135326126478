import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@mui/material";
import { ColumnAlign, ColumnType, SimpleTable } from "@paul-igas/igas-react-components";
import { OptionTableRow } from "./components";
import { getTiposPago } from "../../../../store/operaciones/liquidaciones";
import { hasDispatchError } from "../../../../api/services/service";
import { useModal } from "@paul-igas/igas-react-hooks";
import { GetConfiguration } from "../../../helpers/variablesEstacion";
import PagoEditDialog from "./dialogs/pagoEditDialog";

const TABLE_PAGOS = {
  columns: [
    {
      text: "Tipo pago",
      name: "descripcion",
    },
    {
      text: "Importe",
      name: "importe",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "",
      name: "action",
      options: true,
      icon: true,
    },
  ],
};

export const TiposPagoPagos = (props) => {
  const {
    pagos,
    loadingPagos,
    pago,
    mostrarDenominaciones,
    editPagoDialog,
    openEditPagoDialog,
    onSubmitEditPagoDialog,
  } = useTiposPago(props);

  return (
    <Paper>
      <SimpleTable
        table={TABLE_PAGOS}
        data={pagos}
        loading={loadingPagos}
        showPagination={false}
        rowOptions={
          <OptionTableRow onEdit={openEditPagoDialog} esDenominacion={false} validaEfectivo={mostrarDenominaciones} />
        }
      />

      <PagoEditDialog
        open={editPagoDialog.isShowing}
        onClose={editPagoDialog.close}
        postSubmit={onSubmitEditPagoDialog}
        data={pago}
      />
    </Paper>
  );
};

export default TiposPagoPagos;

const useTiposPago = (props) => {
  const { pagos, onUpdate, mostrarDenominaciones, screen } = props;
  const { loading } = useSelector((state) => state.liquidaciones);
  const dispatch = useDispatch();
  const [pago, setPago] = useState({});

  const editPagoDialog = useModal(false);

  const openEditPagoDialog = (row) => {
    setPago(row);
    editPagoDialog.open();
  };

  const asignaTiposPago = ({ payload }) => {
    let _tmpPagos = payload.map((p) => {
      return { ...p, importe: 0, idTipoPago: p.id };
    });
    onUpdate(_tmpPagos);
  };

  const onSubmitEditPagoDialog = (newRow) => {
    const index = pagos.findIndex((p) => p.id === newRow.id);
    let tmp = pagos.slice();
    tmp[index] = newRow;
    onUpdate(tmp);
  };

  const initTipos = () => {
    if ((pagos?.length ?? 0) <= 0) {
      dispatch(getTiposPago({ activo: true }))
        .then(hasDispatchError)
        .then(asignaTiposPago)
        .catch(() => {});
    }
  };

  useEffect(initTipos, []);

  return {
    pagos,
    loadingPagos: loading.getTiposPago,
    pago,
    editPagoDialog,
    mostrarDenominaciones,
    openEditPagoDialog,
    onSubmitEditPagoDialog,
  };
};
