import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAlertas } from "../../store/consultas/dashboard";
import { hasDispatchError } from "../../api/services/service";
import { useEffect } from "react";

function obtenerFechas() {
  const hoy = new Date();
  const ayer = new Date();

  ayer.setDate(hoy.getDate() - 1);

  const formatoFecha = (fecha) => {
    const año = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, "0");
    const dia = String(fecha.getDate()).padStart(2, "0");
    return `${año}-${mes}-${dia}`;
  };

  return {
    fechaIni: formatoFecha(ayer),
    fechaFin: formatoFecha(hoy),
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  height: "100%",
  maxHeight: "400px",
  overflowY: "auto",
}));

export const Alertas = () => {
  const dispatch = useDispatch();
  const { fechaIni, fechaFin } = obtenerFechas();
  const { loading, alertas = [] } = useSelector((state) => state.dashboard);
  const loadingDialog = loading.getAlertas;
  const initialize = () => {
    handleCloseError();
    dispatch(getAlertas({ fechaIni, fechaFin }))
      .then(hasDispatchError)
      .catch(() => {});
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
  };

  useEffect(initialize, []);
  return (
    <>
      <Item>
        {loadingDialog ? (
          <>
            <Skeleton animation='pulse' width='100%' height='100%' />
          </>
        ) : (
          <>
            <Typography>Alertas</Typography>
            <Stack sx={{ width: "100%" }} spacing={2}>
              {alertas.map((notif, index) => (
                <Paper key={index} elevation={1} style={{ padding: "0" }}>
                  <Alert severity='warning' sx={{ fontSize: "0.70rem" }}>
                    <AlertTitle>{notif.titulo}</AlertTitle>
                    Turno: {notif.turno} Fecha: {new Date(notif.fecha).toLocaleDateString()}{" "}
                    {notif.devolucion > 0 ? `Litros: ${notif.devolucion}` : ""} Importe: ${notif.importe.toFixed(2)}
                  </Alert>
                </Paper>
              ))}
            </Stack>
          </>
        )}
      </Item>
    </>
  );
};
