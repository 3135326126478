import { Button, Grid } from "@mui/material";
import { DangerButton } from "@paul-igas/igas-react-components";

const sxBackButton = {
  color: "black",
  backgroundColor: "gainsboro",
  "&:hover": {
    backgroundColor: "#B4B3B2",
  },
};

export function StepperManager(props) {
  const { disableBackButton, disableNextButton, onCancel, onReturn, onContinue } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <DangerButton variant='contained' size='small' disableElevation onClick={onCancel} fullWidth>
          Cancelar
        </DangerButton>
      </Grid>
      <Grid item xs>
        <Button
          variant='contained'
          sx={sxBackButton}
          size='small'
          disableElevation
          disabled={disableBackButton}
          onClick={onReturn}
          fullWidth
        >
          Atras
        </Button>
      </Grid>
      <Grid item xs>
        <Button
          variant='contained'
          size='small'
          disableElevation
          disabled={disableNextButton}
          onClick={onContinue}
          color='primary'
          fullWidth
        >
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
}
