import { Box } from "@mui/material";

export const InfoDetailCard = ({ title, value }) => {
  return (
    <Box display='flex'>
      <Box flexGrow={1}>{title}</Box>
      <Box ml={1}>{value}</Box>
    </Box>
  );
};
