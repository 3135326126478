import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetIslas } from "../../api/services/consultas/dispensarios";
import { GetVentasDiversos, GetVentasDiversosGlobal } from "../../api/services/consultas/ventasDiversos";
import { GetLecturas, GetIslasEnCeros, GetByGlobalTurno } from "../../api/services/consultas/turnoVol";
import { GetDespachadores } from "../../api/services/consultas/usuarios";
import { hasErrorRedux } from "../../api/services/service";
import { GetTiposPago } from "../../api/services/consultas/tiposPago";
import { GetDenominaciones } from "../../api/services/consultas/denominaciones";
import {
  Delete,
  Get,
  GetAll,
  GetByIslaTurno,
  Update,
  InsertLiqEnCeros,
  Insert,
} from "../../api/services/operaciones/liquidaciones";

import { Export, Preview } from "../../api/services/reportes/liquidaciones";

export const selectAll = createAsyncThunk("liquidacion/selectAll", async (params, { rejectWithValue }) => {
  try {
    return await GetAll(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const select = createAsyncThunk("liquidacion/select", async (params, { rejectWithValue }) => {
  try {
    return await Get(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("liquidacion/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const update = createAsyncThunk("liquidacion/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const remove = createAsyncThunk("liquidacion/delete", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const selectByIslaTurno = createAsyncThunk("liquidacion/byIslaTurno", async (params, { rejectWithValue }) => {
  try {
    return await GetByIslaTurno(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getIslas = createAsyncThunk("liquidacion/islas", async (_, { rejectWithValue }) => {
  try {
    return await GetIslas();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getDespachadores = createAsyncThunk("liquidacion/despachadores", async (_, { rejectWithValue }) => {
  try {
    return await GetDespachadores();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getLecturas = createAsyncThunk("liquidacion/lecturas", async (params, { rejectWithValue }) => {
  try {
    return await GetLecturas(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getIslasEnCeros = createAsyncThunk("liquidacion/islasEnCeros", async (params, { rejectWithValue }) => {
  try {
    return await GetIslasEnCeros(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getVentasDiversos = createAsyncThunk("liquidacion/ventasDiversos", async (params, { rejectWithValue }) => {
  try {
    return await GetVentasDiversos(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getTiposPago = createAsyncThunk("liquidacion/tiposPago", async (params, { rejectWithValue }) => {
  try {
    return await GetTiposPago(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getDenominaciones = createAsyncThunk("liquidacion/denominaciones", async (_, { rejectWithValue }) => {
  try {
    return await GetDenominaciones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const liquidarIslasEnCeros = createAsyncThunk(
  "liquidacion/liquidarEnCeros",
  async (params, { rejectWithValue }) => {
    try {
      return await InsertLiqEnCeros(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const selectByGlobalTurno = createAsyncThunk(
  "liquidacion/byGlobalTurno",
  async (params, { rejectWithValue }) => {
    try {
      return await GetByGlobalTurno(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getVentasGlobalTurno = createAsyncThunk(
  "liquidacion/ventasByGlobalTurno",
  async (params, { rejectWithValue }) => {
    try {
      return await GetVentasDiversosGlobal(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const preview = createAsyncThunk("liquidacion/preview", async (params, { rejectWithValue }) => {
  try {
    return await Preview(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const exportToPDF = createAsyncThunk("liquidacion/export", async (params, { rejectWithValue }) => {
  try {
    return await Export(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  liquidaciones: [],
  selectedLiq: {},
  error: {},
  islas: [],
  despachadores: [],
  turnoActual: {},
  lecturaProductoModificado: false,
  jarreoConsignacionModificados: false,
  productoModificado: false,
  loading: {
    getAll: false,
    select: false,
    selectByIslaTurno: false,
    insert: false,
    delete: false,
    update: false,
    getIslas: false,
    getDespachadores: false,
    getLecturas: false,
    getIslasEnCeros: false,
    getVentasDiversos: false,
    getTiposPago: false,
    getDenominaciones: false,
    insertLiqEnCeros: false,
    getGlobalTurno: false,
    getVentasGlobal: false,
    export: false,
    preview: false,
  },
};

const slice = createSlice({
  name: "liquidaciones",
  initialState,
  reducers: {
    setIdLiquidacion: (state, action) => {
      state.selectedLiq = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
    setTurnoActual: (state, action) => {
      state.turnoActual = action.payload;
    },
    setLecturaProductoModificado: (state, action) => {
      state.lecturaProductoModificado = action.payload;
    },
    setJarreoConsignacionModificados: (state, action) => {
      state.jarreoConsignacionModificados = action.payload;
    },
    setProductoModificado: (state, action) => {
      state.productoModificado = action.payload;
    },    
  },
  extraReducers: (builder) => {
    // SelectAll
    builder.addCase(selectAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(selectAll.fulfilled, (state, action) => {
      state.liquidaciones = action.payload;
      state.error = {};
      state.loading.getAll = false;
    });
    builder.addCase(selectAll.rejected, (state, action) => {
      state.error = action.payload;
      state.liquidaciones = [];
      state.loading.getAll = false;
    });
    // Select
    builder.addCase(select.pending, (state) => {
      state.loading.select = true;
    });
    builder.addCase(select.fulfilled, (state) => {
      state.error = {};
      state.loading.select = false;
    });
    builder.addCase(select.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.select = false;
    });
    // SelectByIslaTurno
    builder.addCase(selectByIslaTurno.pending, (state) => {
      state.loading.selectByIslaTurno = true;
    });
    builder.addCase(selectByIslaTurno.fulfilled, (state) => {
      state.error = {};
      state.loading.selectByIslaTurno = false;
    });
    builder.addCase(selectByIslaTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.selectByIslaTurno = false;
    });
    // Registrar
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    // Actualizar
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });
    // Eliminar
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.error = {};
      state.loading.delete = false;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
    // Islas
    builder.addCase(getIslas.pending, (state) => {
      state.loading.getIslas = true;
    });
    builder.addCase(getIslas.fulfilled, (state, action) => {
      state.error = {};
      state.islas = action.payload;
      state.loading.getIslas = false;
    });
    builder.addCase(getIslas.rejected, (state, action) => {
      state.error = action.payload;
      state.islas = [];
      state.loading.getIslas = false;
    });
    // Despachadores
    builder.addCase(getDespachadores.pending, (state) => {
      state.loading.getDespachadores = true;
    });
    builder.addCase(getDespachadores.fulfilled, (state, action) => {
      state.error = {};
      state.despachadores = action.payload;
      state.loading.getDespachadores = false;
    });
    builder.addCase(getDespachadores.rejected, (state, action) => {
      state.error = action.payload;
      state.despachadores = [];
      state.loading.getDespachadores = false;
    });
    // Lecturas
    builder.addCase(getLecturas.pending, (state) => {
      state.loading.getLecturas = true;
    });
    builder.addCase(getLecturas.fulfilled, (state) => {
      state.error = {};
      state.loading.getLecturas = false;
    });
    builder.addCase(getLecturas.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getLecturas = false;
    });
    // Ventas diversos
    builder.addCase(getVentasDiversos.pending, (state) => {
      state.loading.getVentasDiversos = true;
    });
    builder.addCase(getVentasDiversos.fulfilled, (state) => {
      state.error = {};
      state.loading.getVentasDiversos = false;
    });
    builder.addCase(getVentasDiversos.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getVentasDiversos = false;
    });
    // Tipos pago
    builder.addCase(getTiposPago.pending, (state) => {
      state.loading.getTiposPago = true;
    });
    builder.addCase(getTiposPago.fulfilled, (state) => {
      state.error = {};
      state.loading.getTiposPago = false;
    });
    builder.addCase(getTiposPago.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getTiposPago = false;
    });
    // Denominaciones
    builder.addCase(getDenominaciones.pending, (state) => {
      state.loading.getDenominaciones = true;
    });
    builder.addCase(getDenominaciones.fulfilled, (state) => {
      state.error = {};
      state.loading.getDenominaciones = false;
    });
    builder.addCase(getDenominaciones.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getDenominaciones = false;
    });
    // Vista previa impresión
    builder.addCase(preview.pending, (state) => {
      state.loading.preview = true;
    });
    builder.addCase(preview.fulfilled, (state) => {
      state.error = {};
      state.loading.preview = false;
    });
    builder.addCase(preview.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.preview = false;
    });
    // Exportar PDF
    builder.addCase(exportToPDF.pending, (state) => {
      state.loading.export = true;
    });
    builder.addCase(exportToPDF.fulfilled, (state) => {
      state.error = {};
      state.loading.export = false;
    });
    builder.addCase(exportToPDF.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.export = false;
    });
    //GetIslasEnCeros
    builder.addCase(getIslasEnCeros.pending, (state) => {
      state.loading.getIslasEnCeros = true;
    });
    builder.addCase(getIslasEnCeros.fulfilled, (state) => {
      state.error = {};
      state.loading.getIslasEnCeros = false;
    });
    builder.addCase(getIslasEnCeros.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getIslasEnCeros = false;
    });
    //LiquidarIslasEnCeros
    builder.addCase(liquidarIslasEnCeros.pending, (state) => {
      state.loading.insertLiqEnCeros = true;
    });
    builder.addCase(liquidarIslasEnCeros.fulfilled, (state) => {
      state.error = {};
      state.loading.insertLiqEnCeros = false;
    });
    builder.addCase(liquidarIslasEnCeros.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insertLiqEnCeros = false;
    });
    //Liquidación global del turno
    builder.addCase(selectByGlobalTurno.pending, (state) => {
      state.loading.getGlobalTurno = true;
    });
    builder.addCase(selectByGlobalTurno.fulfilled, (state) => {
      state.error = {};
      state.loading.getGlobalTurno = false;
    });
    builder.addCase(selectByGlobalTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getGlobalTurno = false;
    });
    //Ventas de productos diversos globales del turno
    builder.addCase(getVentasGlobalTurno.pending, (state) => {
      state.loading.getVentasGlobal = true;
    });
    builder.addCase(getVentasGlobalTurno.fulfilled, (state) => {
      state.error = {};
      state.loading.getVentasGlobal = false;
    });
    builder.addCase(getVentasGlobalTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getVentasGlobal = false;
    });
  },
});

export default slice.reducer;
export const {
  setIdLiquidacion,
  clearErrors,
  setTurnoActual,
  setLecturaProductoModificado,
  setJarreoConsignacionModificados,
  setProductoModificado,
} = slice.actions;
