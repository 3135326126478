import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { InfoPanel, LoadingPanel, StepperManager } from "../../../components/operaciones/preliquidacion";
import {
  CombustibleStep,
  GeneralStep,
  PagosStep,
  ProductosStep,
  ResumenStep,
} from "../../../components/operaciones/preliquidacion/steps";
import { CancelDialog } from "../../../components/operaciones/preliquidacion/dialogs";
import { useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../../api/services/service";
import { insert, selectByIslaTurno } from "../../../store/operaciones/preliquidaciones";
import { WarningMessage } from "@paul-igas/igas-react-components";

const steps = ["Datos generales", "Combustibles", "Productos diversos", "Pagos", "Resumen"];

export function AgregarPreliquidacion() {
  const data = useAgregarPreliquidacion();

  const cancelarDlg = useModal(false);

  return (
    <>
      {!data.saving && (
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5' paragraph>
              Agregar preliquidación
            </Typography>
          </Grid>

          <Grid item>
            <StepperManager
              disableBackButton={data.disableBtns || data.step === 0}
              disableNextButton={data.validaBotonSiguiente()}
              onCancel={cancelarDlg.open}
              onReturn={data.anteriorStep}
              onContinue={data.siguienteStep}
            />
          </Grid>
        </Grid>
      )}

      <CancelDialog open={cancelarDlg.isShowing} onClose={cancelarDlg.close} />

      <WarningMessage
        open={data.liquidadaMsg.isShowing}
        onClose={data.liquidadaMsg.close}
        text='Isla contiene preliquidación.'
      />

      {data.saving ? (
        <LoadingPanel title='Guardando preliquidación...' size={100} />
      ) : (
        <>
          <Paper style={{ marginBottom: 8 }}>
            <Stepper activeStep={data.step} style={{ padding: 16 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>

          {data.step < 4 && <InfoPanel data={data} />}
          {data.turnoCargado && data.step === 0 && <GeneralStep data={data} />}
          {data.turnoCargado && data.step === 1 && <CombustibleStep data={data} />}
          {data.turnoCargado && data.step === 2 && <ProductosStep data={data} />}
          {data.turnoCargado && data.step === 3 && <PagosStep data={data} />}
          {data.turnoCargado && data.step === 4 && <ResumenStep data={data} />}
        </>
      )}
    </>
  );
}

export default AgregarPreliquidacion;

const useAgregarPreliquidacion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.preliquidaciones);
  const [step, setStep] = useState(0);
  const [turno, setTurno] = useState(null);
  const [isla, setIsla] = useState(null);
  const [despachador, setDespachador] = useState(null);
  const [disableBtns, setDisableBtns] = useState(true);
  const [saving, setSaving] = useState(false);
  const [liquidada, setLiquidada] = useState(false);

  const liquidadaMsg = useModal(false);

  const wait = (ms) => new Promise((r, j) => setTimeout(r, ms));

  const validateliq = ({ payload }) => {
    setLiquidada((payload?.id ?? 0) > 0);
  };

  const initIsla = () => {
    if ((turno?.id ?? 0) > 0 && (isla?.id ?? 0) > 0) {
      dispatch(selectByIslaTurno({ idTurno: turno.id, idIsla: isla.id }))
        .then(hasDispatchError)
        .then(validateliq)
        .catch(() => {});
    }
  };

  const totales = {
    combustibles: isla?.lecturas?.reduce((a, b) => a + b.importe, 0),
    productos: isla?.productos?.reduce((a, b) => a + b.importe, 0),
    pagos: isla?.pagos?.reduce((a, b) => a + (b.importe ?? 0), 0),
    denominaciones: isla?.denominaciones?.reduce((a, b) => a + b.venta, 0),
  };

  const diferencia = round(totales.combustibles, 2) + round(totales.productos, 2) - round(totales.pagos, 2);

  const validaBotonSiguiente = () => {
    if (disableBtns) return true;
    if ((turno?.id ?? 0) === 0) return true;
    if (step > 3) return true;
    if (step === 3 && diferencia > 0.5) return true;

    return false;
  };

  const siguienteStep = () => {
    if (step === 0 && liquidada) {
      liquidadaMsg.open();
      return;
    }

    setStep((step) => step + 1);
  };

  const goList = () => {
    navigate("/operaciones/preliquidacion");
  };

  const onSave = async () => {
    dispatch(
      insert({
        idTurno: turno.id,
        idIsla: isla.id,
        noIsla: isla.isla,
        idDespachador: despachador.id,
        totalCombustible: totales.combustibles,
        totalDiversos: totales.productos,
        totalGeneral: totales.productos + totales.combustibles,
        totalPagado: totales.pagos,
        lecturasCombustibles: isla.lecturas,
        productosDiversos: isla.productos.map((p) => ({ ...p, idProductoDiverso: p.id })),
        tiposPagos: isla.pagos.map((p) => ({ ...p, idTipoPago: p.id })),
        denominaciones: isla.denominaciones.map((p) => ({ ...p, idDenominacion: p.id })),
      })
    )
      .then(hasDispatchError)
      .then(goList)
      .catch(() => {});
  };

  useEffect(() => {
    loading.insert ? setSaving(true) : setSaving(false);
  }, [loading]);

  useEffect(initIsla, [isla]);

  return {
    step,
    turno,
    turnoCargado: turno?.id > 0,
    isla,
    despachador,
    disableBtns,
    totales,
    saving,
    diferencia,
    liquidadaMsg,
    asignaTurno: setTurno,
    asignaIsla: setIsla,
    asignaDespachador: setDespachador,
    siguienteStep,
    anteriorStep: () => setStep((step) => step - 1),
    desactivaStepBtn: () => setDisableBtns(true),
    activaStepBtn: () => setDisableBtns(false),
    validaBotonSiguiente,
    onSave,
  };
};

const round = (num, decimal) => {
  return +(Math.round(num + "e+" + decimal) + "e-" + decimal);
};
