import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetConfiguration } from "../../../helpers/variablesEstacion";
import { hasDispatchError } from "../../../../api/services/service";
import { getTiposPago } from "../../../../store/operaciones/liquidaciones";

export const useTipoPagoValido = (_tipoPago) => {
  const dispatch = useDispatch();
  const [valorVariable, setValorVariable] = useState(-1);

  const v = GetConfiguration(_tipoPago)?.valor ?? -1;

  const validaTipoPago = ({ payload }) => {
    let tp = payload.find((x) => x.id === v);
    if ((tp?.id ?? -1) < 0) setValorVariable(-1);
    else setValorVariable(v);
  };

  const init = () => {
    dispatch(getTiposPago({ activo: true }))
      .then(hasDispatchError)
      .then(validaTipoPago)
      .catch(() => {});
  };

  useEffect(init, []);

  return {
    valorVariable,
  };
};
