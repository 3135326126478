import { UseAuthContext, UseAuthTheme } from "../../../contexts";
import {
  Collapse,
  Drawer,
  Toolbar,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Hidden,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logos/appMenu.png";
import { validRoles } from "../../../components/helpers/user";
import * as Icons from "@mui/icons-material";
import { FlatButton } from "@paul-igas/igas-react-components";

const DrawerContainer = styled(Drawer)(() => ({
  width: 200,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: 200,
  },
}));

const DrawerPaper = styled("img")(() => ({
  width: 120,
}));

export default function DrawerLeftMenu() {
  const theme = UseAuthTheme();
  const { routes, profile, displayWidth, leftMenu, title } = UseAuthContext();

  const [selectedItem, setSelectedItem] = useState("Inicio");
  const [opened, setOpened] = useState("");
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenCloseGroup = (event) => {
    let _opened = event.target.innerText;
    if (_opened !== opened) {
      setOpenMenu(true);
      setOpened(_opened);
    } else setOpenMenu(!openMenu);
  };

  const handleListItemClick = (event, item) => {
    setSelectedItem(item);
  };

  function filterRoutes() {
    const list = routes.map((route) => ({ ...route, childrens: removeRoutes(route.childrens) }));
    return list.map((link) =>
      link.text ?? "" ? (
        <CustomLink
          key={link.text}
          link={link}
          menu={opened}
          isOpen={openMenu}
          setOpen={handleOpenCloseGroup}
          itemClick={handleListItemClick}
          itemSelected={selectedItem}
        />
      ) : null
    );
  }

  function removeRoutes(listRoutes = []) {
    const list = listRoutes.map((route) => ({ ...route, childrens: removeRoutes(route.childrens) }));
    return list.filter((children) => !children.roles || validRoles(profile.role, children.roles));
  }

  return (
    <DrawerContainer
      variant={displayWidth === "xs" || displayWidth === "sm" ? "temporary" : "persistent"}
      anchor='left'
      open={leftMenu.isShowing}
      onClose={leftMenu.close}
      sx={{
        zIndex: theme.zIndex.drawer - 1,
      }}
    >
      <Toolbar>
        <DrawerPaper src={logo} alt={"logo"}></DrawerPaper>
      </Toolbar>

      <Hidden smUp>
        <Typography style={{ marginLeft: 16 }} variant='subtitle2' noWrap>
          {title}
        </Typography>
      </Hidden>

      <List dense>{filterRoutes()}</List>
    </DrawerContainer>
  );
}

const CustomLink = (props) => {
  const { link, menu = "", isOpen = false, itemSelected = "", setOpen = () => {}, itemClick = () => {} } = props;
  const [opened, setOpened] = useState(false);

  const searchIcon = (iconName) => {
    let DynamicIcon = Icons[iconName];

    return <DynamicIcon fontSize='small' />;
  };

  const handleMenuOption = () => {
    setOpened(menu === link.text && isOpen);
  };

  useEffect(handleMenuOption, [menu, isOpen]);

  if (link.route)
    return (
      <ListItemButton
        component={Link}
        to={link.route}
        style={{ paddingLeft: link.nested }}
        selected={itemSelected === link.text}
        onClick={(event) => itemClick(event, link.text)}
        // sx={{ ["&.Mui-selected"]: { backgroundColor: "#FED422" } }}
      >
        {Boolean(link.iconName) && link.route === "/" && <ListItemIcon>{searchIcon(link.iconName)}</ListItemIcon>}
        <ListItemText
          sx={{ ml: link.route === "/" ? -4 : 0 }}
          primary={
            <Typography style={{ fontWeight: link.route === "/" ?? 6000 }} variant='caption' noWrap>
              {link.text}
            </Typography>
          }
        />
      </ListItemButton>
    );

  return (
    <>
      <ListItemButton onClick={(event) => setOpen(event)} style={{ paddingLeft: link.nested }}>
        {Boolean(link.iconName) && <ListItemIcon>{searchIcon(link.iconName)}</ListItemIcon>}
        <ListItemText sx={{ ml: -4 }}>
          <Typography style={{ fontWeight: 6000 }} variant='caption' noWrap>
            {link.text}
          </Typography>
        </ListItemText>
        <FlatButton disabled>{opened ? <ExpandLess /> : <ExpandMore />}</FlatButton>
      </ListItemButton>
      <Collapse in={opened} timeout='auto' unmountOnExit>
        <List dense component='div' disablePadding>
          {link.childrens.map((link2) => (
            <CustomLink key={link2.text} link={link2} itemClick={itemClick} itemSelected={itemSelected} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
