import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { GetConfiguration } from "../../../helpers/variablesEstacion";
import OptionTableRowComb from "./components/optionTableRowComb";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { decimalVal, monedaMX } from "@paul-igas/igas-react-hooks";
import AlertaLecturas from "./components/alertaLecturas";

const CellHead = styled(TableCell)(() => ({ fontWeight: "bold", color: "black" }));

export function TableCombustible({ lecturas, openEditDialog, openEditLecturasDialog, esUltimoTurno, totales }) {
  if (lecturas?.length <= 0) return <AlertaLecturas />;

  return (
    <TableContainer>
      <Table size='small'>
        <TableHeadCombustible />
        <TableBody>
          {lecturas.map((lectura) => (
            <RowCombustible
              key={lectura.id}
              rowData={lectura}
              openEditDialog={openEditDialog}
              openEditLecturasDialog={openEditLecturasDialog}
              esUltimoTurno={esUltimoTurno}
            />
          ))}
        </TableBody>
        <TotalsCombustible totalsRow={totales} />
      </Table>
    </TableContainer>
  );
}

export default TableCombustible;

const TableHeadCombustible = () => {
  const manejaConsignacion = GetConfiguration(7)?.valor;
  const diferenciaLecturas = GetConfiguration(8)?.valor;

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: "whitesmoke" }}>
        <CellHead></CellHead>
        <CellHead>Dispensario</CellHead>
        <CellHead align='right'>Lect. Inicial</CellHead>
        <CellHead align='right'>Lect. Final</CellHead>
        <CellHead align='right'>Vol. Jarreo</CellHead>
        {manejaConsignacion && <CellHead align='right'>Vol. Consignación</CellHead>}
        {diferenciaLecturas && <CellHead align='right'>Dif. lecturas</CellHead>}
        <CellHead align='right'>Volumen</CellHead>
        <CellHead align='right'>Precio</CellHead>
        <CellHead align='right'>Importe</CellHead>
        <CellHead />
      </TableRow>
    </TableHead>
  );
};

const RowCombustible = ({ rowData, openEditDialog, openEditLecturasDialog, esUltimoTurno }) => {
  const manejaConsignacion = GetConfiguration(7)?.valor;
  const diferenciaLecturas = GetConfiguration(8)?.valor;
  const [expandible, setExpandible] = useState(false);

  return (
    <>
      <TableRow>
        {!rowData.detalle && <TableCell component='th' scope='row' align='right'></TableCell>}
        {rowData.detalle && (
          <TableCell style={{ padding: 1 }} component='th' scope='row' align='right'>
            {!expandible && (
              <Button onClick={() => setExpandible(true)}>
                <ChevronRight fontSize='small' color='action' />
              </Button>
            )}
            {expandible && (
              <Button onClick={() => setExpandible(false)}>
                <ExpandMore fontSize='small' color='action' />
              </Button>
            )}
          </TableCell>
        )}
        <TableCell>
          {rowData.noDispensario} - {rowData.nombreCombustible}
        </TableCell>
        <TableCell align='right'>{decimalVal(3).format(rowData.lecturaInicial)}</TableCell>
        <TableCell align='right'>{decimalVal(3).format(rowData.lecturaFinal)}</TableCell>
        <TableCell align='right'>{decimalVal(3).format(rowData.devolucion)}</TableCell>
        {manejaConsignacion && <TableCell align='right'>{decimalVal(3).format(rowData.consignacion)}</TableCell>}
        {diferenciaLecturas && <TableCell align='right'>{decimalVal(3).format(rowData.diferenciaLecturas)}</TableCell>}
        <TableCell align='right'>{decimalVal(3).format(rowData.diferencia)}</TableCell>
        <TableCell align='right'>
          {rowData.precioCombustible === "-" ? "-" : monedaMX.format(rowData.precioCombustible)}
        </TableCell>
        <TableCell align='right'>{monedaMX.format(rowData.importe)}</TableCell>
        {!rowData.detalle && (
          <TableCell align='right'>
            <OptionTableRowComb
              data={rowData}
              onEdit={openEditDialog}
              onEditLecturas={openEditLecturasDialog}
              esUltimoTurno={esUltimoTurno}
            />
          </TableCell>
        )}
        {rowData.detalle && <TableCell colSpan={1} />}
      </TableRow>
      {expandible &&
        rowData.detalle.map((row) => (
          <TableRow key={row.id}>
            <TableCell colSpan={2} />
            <RowDetail value={decimalVal(3).format(row.lecturaInicial)} />
            <RowDetail value={decimalVal(3).format(row.lecturaFinal)} />
            <RowDetail value={decimalVal(3).format(row.devolucion)} />
            {manejaConsignacion && <RowDetail value={decimalVal(3).format(row.consignacion)} />}
            {diferenciaLecturas && <RowDetail value={decimalVal(3).format(row.diferenciaLecturas)} />}
            <RowDetail value={decimalVal(3).format(row.diferencia)} />
            <RowDetail value={monedaMX.format(row.precioCombustible)} />
            <RowDetail value={monedaMX.format(row.importe)} />
            <TableCell align='right'>
              <OptionTableRowComb
                data={{
                  ...row,
                  id: rowData.id,
                  idDet: row.id,
                  noDispensario: rowData.noDispensario,
                  noManguera: rowData.noManguera,
                  nombreCombustible: rowData.nombreCombustible,
                }}
                onEdit={openEditDialog}
                onEditLecturas={openEditLecturasDialog}
                esUltimoTurno={esUltimoTurno}
              />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

const RowDetail = ({ value }) => {
  return (
    <TableCell style={{ color: "gray", fontSize: 11 }} align='right'>
      {value}
    </TableCell>
  );
};

const TotalsCombustible = ({ totalsRow }) => {
  const manejaConsignacion = GetConfiguration(7)?.valor;
  const diferenciaLecturas = GetConfiguration(8)?.valor;

  return (
    <TableFooter style={{}}>
      <TableRow>
        <CellHead variant='head' colSpan={4} align='right'>
          Totales
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.volJarreo}
        </CellHead>
        {manejaConsignacion && (
          <CellHead variant='head' align='right'>
            {totalsRow.volConsignacion}
          </CellHead>
        )}
        {diferenciaLecturas && (
          <CellHead variant='head' align='right'>
            {totalsRow.diferenciaLecturas}
          </CellHead>
        )}
        <CellHead variant='head' align='right'>
          {totalsRow.volumen}
        </CellHead>
        <CellHead variant='head' align='right'>
          -
        </CellHead>
        <CellHead variant='head' align='right'>
          {totalsRow.importe}
        </CellHead>
        <CellHead></CellHead>
      </TableRow>
    </TableFooter>
  );
};
