import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useModal, formatDateTimeUS } from "@paul-igas/igas-react-hooks";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from "@mui/material";
import { hasDispatchError } from "../../../../api/services/service";
import { insert } from "../../../../store/operaciones/turnos";

const DEFAULT = { fecha: formatDateTimeUS(new Date()) };

export function InsertDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.turnos);
  const { handleSubmit, reset, control } = useForm();
  const [errors, setErrors] = useState({});

  const successMsg = useModal(false);

  function isValid(_data) {
    let _hoy = formatDateTimeUS(new Date());
    let temp = [];
    temp.fecha = new Date(_data.fecha) <= new Date(_hoy) ? "" : "Fecha no permitida.";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  }

  function initDialog() {
    if (open) {
      setErrors({});
      reset(DEFAULT);
    }
  }

  const onSubmit = (data) => {
    if (isValid(data)) {
      setErrors({});
      dispatch(insert(data))
        .then(hasDispatchError)
        .then(onClose)
        .then(successMsg.open)
        .then(postSubmit)
        .catch(() => {});
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>Agregar turno</DialogTitle>
        <DialogContent dividers>
          <form id='form-turno-insert-dlg' onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            <Grid container spacing={3}>
              <Grid item>
                <FormControl fullWidth size='small'>
                  <Controller
                    name='fecha'
                    defaultValue={DEFAULT.fecha}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant='standard'
                        value={field.value}
                        disabled={loading.insert}
                        error={Boolean(errors.fecha)}
                        helperText={errors.fecha}
                        autoFocus
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size='small'
                        type='date'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.insert}
            type='submit'
            form='form-turno-insert-dlg'
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
    </>
  );
}

export default InsertDialog;
