import { AuthContext } from "../../contexts";
import Default from "../../themes/default";
import { useWidth } from "../../hooks";
import { CssBaseline, Container, Backdrop, CircularProgress } from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import AppBarTopMenu from "./components/appBarTopMenu";
import DrawerLeftMenu from "./components/drawerLeftMenu";
import LogoutDialog from "./components/logoutDialog";
import { useModal } from "@paul-igas/igas-react-hooks";
import { AlertMessage } from "./components/alertMessage";

export const DRAWER_WIDTH = 200;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export function Authorize({ children, ...props }) {
  const theme = Default;
  const context = {
    ...props,
    leftMenu: useModal(true),
    logoutDialog: useModal(false),
    displayWidth: useWidth(),
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={context}>
        <div style={{ display: "flex" }}>
          <CssBaseline />
          <AppBarTopMenu />
          <DrawerLeftMenu />
          <Container fixed>
            <main
              style={{
                flexGrow: 1,
                paddingTop: theme.spacing(3),
                transition: theme.transitions.create("margin", {
                  easing: context.leftMenu.isShowing
                    ? theme.transitions.easing.sharp
                    : theme.transitions.easing.easeOut,
                  duration: context.leftMenu.isShowing
                    ? theme.transitions.duration.leavingScreen
                    : theme.transitions.duration.enteringScreen,
                }),
                marginLeft:
                  context.displayWidth !== "xs" && context.displayWidth !== "sm"
                    ? context.leftMenu.isShowing
                      ? 0
                      : -DRAWER_WIDTH
                    : 0,
              }}
            >
              <DrawerHeader />
              {children}
            </main>
          </Container>
          <LogoutDialog />
          <Backdrop sx={{ zIndex: theme.zIndex.modal + 100 }} open={context.backdrop}>
            <CircularProgress color='primary' />
          </Backdrop>
          <AlertMessage />
        </div>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}
