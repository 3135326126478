import { useState } from "react";
import { ExpandLess, ExpandMore, LockOpen } from "@mui/icons-material";
import { Badge, Button, Grid, Typography } from "@mui/material";
import { UseTurnoContext, UseTurnoTheme } from "../../../contexts";
import { formatDateTimeMX } from "@paul-igas/igas-react-hooks";
import TurnoRow from "./rowElements/turnoRow";

export const DateRow = ({ data }) => {
  const { openGroupDate, setOpenGroupDate } = UseTurnoContext();
  const theme = UseTurnoTheme();
  const [more, setMore] = useState(false);

  const openCount = data.turnos.filter((turno) => turno.estado).length;

  function handleClickMore() {
    if (more) {
      setOpenGroupDate(openGroupDate.filter((group) => group !== data.fecha));
      setMore(false);
    } else {
      setOpenGroupDate([...openGroupDate, data.fecha]);
      setMore(true);
    }
  }

  return (
    <Grid container wrap='nowrap' spacing={2} alignItems='flex-start'>
      <Grid item>
        <Button size='small' onClick={handleClickMore} style={{ minWidth: 0 }}>
          {more ? (
            <ExpandLess color='action' style={{ fontSize: 22 }} />
          ) : (
            <ExpandMore color='action' style={{ fontSize: 22 }} />
          )}
        </Button>
      </Grid>

      <Grid item container direction='column'>
        <Grid item>
          <Typography
            variant='subtitle2'
            gutterBottom
            style={{
              marginTop: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
              fontWeight: "bold",
            }}
          >
            {formatDateTimeMX(data.fecha)}
            {openCount > 0 && (
              <Badge badgeContent={openCount} color='primary'>
                <LockOpen fontSize='small' color='action' />
              </Badge>
            )}
          </Typography>
        </Grid>

        {more && data.turnos.map((turno) => <TurnoRow key={turno.id} turno={turno} />)}
      </Grid>
    </Grid>
  );
};

export default DateRow;
