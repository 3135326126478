import api from "../service";

const _controller = "liquidacion/servicios";

export async function GetAll(params) {
  const res = await api.get(`${_controller}/selectAll`, { params });
  return res.data;
}

export async function Get(params) {
  const res = await api.get(`${_controller}`, { params });
  return res.data;
}

export async function GetByIslaTurno(params) {
  const res = await api.get(`${_controller}/SelectByIslaTurno`, { params });
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(`${_controller}`, params);
  return res.data;
}

export async function Update(params) {
  const res = await api.put(`${_controller}`, params);
  return res.data;
}

export async function Delete(params) {
  const res = await api.delete(`${_controller}`, { params });
  return res.data;
}

export async function InsertLiqEnCeros(params) {
  const res = await api.post(`${_controller}/insertLiquidacionEnCeros`, params);
  return res.data;
}

export default {
  GetAll,
  Get,
  GetByIslaTurno,
  Insert,
  Update,
  Delete,
  InsertLiqEnCeros,
};
