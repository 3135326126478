import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/authRoute";
import { NotFound, SigninOidc, SignOutOidc } from "./pages/auth";
import DashBoard from "./pages/dashboard";
import { Turnos } from "./pages/operaciones";
import {
  AgregarLiquidacion,
  AgregarLiquidacionGlobal,
  EditarLiquidacion,
  Liquidaciones,
} from "./pages/operaciones/liquidaciones";
import { AgregarPreliquidacion, Preliquidaciones } from "./pages/operaciones/preliquidaciones";
import { ConfigReporteVentasDespachador, ConfigReportesResumen } from "./pages/reportes";

function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path='/' element={<DashBoard />} />       
       
        {/* Operaciones */}
        <Route path='/operaciones/*'>
          <Route path='turnos' element={<Turnos />} />
          <Route path='liquidaciones/*'>
            <Route path='detalle' element={<Liquidaciones />} />
            <Route path='agregar' element={<AgregarLiquidacion />} />
            <Route path='editar' element={<EditarLiquidacion />} />
            <Route path='global' element={<AgregarLiquidacionGlobal />} />
          </Route>
        </Route>

        {/* Se pospusieron estas opciones */}
        {/* <Route path='/operaciones/preliquidacion' element={<Preliquidaciones />} />
        <Route path='/operaciones/preliquidacion/agregar' element={<AgregarPreliquidacion />} /> */}

        {/*Reportes*/}
        <Route path='/reportes/*'>
          <Route path='resumenventas' element={<ConfigReportesResumen tipoReporte={1} />} />
          <Route path='ventascombustible' element={<ConfigReportesResumen tipoReporte={2} />} />
          <Route path='ventasdespachador' element={<ConfigReporteVentasDespachador />} />
          <Route path='faltantesysobrantes' element={<ConfigReportesResumen tipoReporte={3} />} />
        </Route>

        {/* En caso de que no exista la ruta, se carga esta página */}
        <Route path='*' element={<NotFound />} />
      </Route>

      <Route path='/signin-oidc' element={<SigninOidc />} />
      <Route path='/signout-oidc' element={<SignOutOidc />} />
    </Routes>
  );
}

export default App;
