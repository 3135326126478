import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, ButtonGroup, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { AccordionFilter } from "@paul-igas/igas-react-components";
import { setFilter } from "../../../store/operaciones/turnos";

export function FilterDrawer({ open, onClose }) {
  const dispatch = useDispatch();
  const { filter, cajeros, loading } = useSelector((state) => state.turnos);
  const [filters, setFilters] = useState(filter);
  const [errors, setErrors] = useState({});
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    if (new Date(data.fechaIni) >= new Date(data.fechaFin)) {
      setErrors((e) => ({
        ...e,
        fechaIni: "'Fecha inicial' no debe ser igual o mayor a 'Fecha final.'",
      }));
      return;
    }

    const array = Object.entries(filters);
    const values = array.map(([key, value]) => [key, { ...value, value: data[key] }]);
    const result = Object.fromEntries(values);

    setErrors({});
    dispatch(setFilter(result));
    onClose();
  };

  const handleCheckChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    const values = filters[name];

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: { ...values, state: checked },
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        {/* Fecha inicial */}
        <AccordionFilter name='fechaIni' label='Fecha inicial' filters={filters} disabled>
          <FormControl fullWidth size='small'>
            <Controller
              name='fechaIni'
              defaultValue={filters.fechaIni.value}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  error={Boolean(errors.fechaIni)}
                  helperText={errors.fechaIni}
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size='small'
                  type='date'
                />
              )}
            />
          </FormControl>
        </AccordionFilter>

        {/* Fecha final */}
        <AccordionFilter name='fechaFin' label='Fecha final' filters={filters} disabled>
          <FormControl fullWidth size='small'>
            <Controller
              name='fechaFin'
              defaultValue={filters.fechaFin.value}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  error={Boolean(errors.fechaFin)}
                  helperText={errors.fechaFin}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size='small'
                  type='date'
                />
              )}
            />
          </FormControl>
        </AccordionFilter>

        {/* Cajeros */}
        <AccordionFilter
          name='idUsuario'
          label='Cajero'
          filters={filters}
          onChange={handleCheckChange}
          loading={loading.getCajeros}
        >
          <FormControl fullWidth size='small'>
            <Controller
              name='idUsuario'
              defaultValue={filters.idUsuario.value}
              control={control}
              render={({ field }) => (
                <Select {...field} variant='standard' fullWidth size='small'>
                  {cajeros.length === 0 && (
                    <MenuItem value=''>
                      <em>Vacío</em>
                    </MenuItem>
                  )}
                  {cajeros.map((cajero) => (
                    <MenuItem key={cajero.id} value={cajero.id}>
                      {cajero.nombreCompleto}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </AccordionFilter>

        <ButtonGroup
          disableElevation
          variant='contained'
          color='secondary'
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: "100%",
          }}
          fullWidth
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button type='submit'>Aplicar</Button>
        </ButtonGroup>
      </form>
    </div>
  );
}
