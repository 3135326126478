import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetConfigurations } from "../api/services/consultas/configuraciones";
import { hasErrorRedux } from "../api/services/service";

export const getConfigurations = createAsyncThunk(
  "configuracionEstacion/configuraciones",
  async (params, { rejectWithValue }) => {
    try {
      return await GetConfigurations(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const STATES = {
  LOADING: 0,
  AUTHORIZED: 200,
  FORBIDDEN: 403,
};

export const ALERTMESSAGETYPES = {
  SUCCESS: 1,
  WARNING: 2,
  DANGER: 3,
};

const initialState = {
  profile: {},
  routes: [
    {
      text: "Inicio",
      route: "/",
      childrens: [],
      iconName: "Home",
      nested: 16,
    },
    {
      text: "Operaciones",
      iconName: "Work",
      childrens: [
        {
          text: "Administrar turnos",
          route: "/operaciones/turnos",
          roles: ["Administrador", "Cajero"],
          childrens: [],
          nested: 32,
        },
        // {
        //   text: "Preliquidaciones",
        //   route: "/operaciones/preliquidacion",
        //   roles: ["Despachador"],
        //   childrens: [],
        //   nested: 32,
        // },
        {
          text: "Liquidaciones del turno",
          route: "/operaciones/liquidaciones/detalle",
          roles: ["Administrador", "Cajero"],
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      text: "Reportes",
      iconName: "Assignment",
      childrens: [
        {
          text: "Resumen de Ventas",
          route: "/reportes/resumenventas",
          childrens: [],
          nested: 32,
        },
        {
          text: "Ventas por Combustible",
          route: "/reportes/ventascombustible",
          childrens: [],
          nested: 32,
        },
        {
          text: "Ventas por Despachador",
          route: "/reportes/ventasdespachador",
          childrens: [],
          nested: 32,
        },
        {
          text: "Faltantes y Sobrantes",
          route: "/reportes/faltantesysobrantes",
          childrens: [],
          nested: 32,
        },
      ],
    },
    { route: "/operaciones/preliquidacion/agregar", roles: ["Despachador"] },
    { route: "/operaciones/preliquidacion/editar", roles: ["Despachador"] },
    { route: "/operaciones/liquidaciones/agregar", roles: ["Administrador", "Cajero"] },
    { route: "/operaciones/liquidaciones/editar", roles: ["Administrador", "Cajero"] },
    { route: "/operaciones/liquidaciones/global", roles: ["Administrador", "Cajero"] },
  ],
  state: STATES.LOADING,
  stations: [],
  error: {},
  backdrop: false,
  configurations: [],
  loading: {
    getConfigurations: false,
  },
  progresBarApp: false,
  alertMessage: {},
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setStations: (state, action) => {
      state.stations = action.payload;
    },
    setBackdrop: (state, action) => {
      state.backdrop = action.payload;
    },
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
    },
    setProgressBarApp: (state, action) => {
      state.progresBarApp = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigurations.pending, (state) => {
      state.loading.getConfigurations = true;
    });
    builder.addCase(getConfigurations.fulfilled, (state, action) => {
      state.error = {};
      state.configurations = action.payload;
      state.loading.getConfigurations = false;
    });
    builder.addCase(getConfigurations.rejected, (state, action) => {
      state.error = action.payload;
      state.configurations = [];
      state.loading.getConfigurations = false;
    });
  },
});

export default slice.reducer;

export const { setProfile, setState, setStations, setBackdrop, setConfigurations, setProgressBarApp, setAlertMessage } =
  slice.actions;
