import { useSelector } from "react-redux";

export const GetConfiguration = (idVar) => {
  const { configurations } = useSelector((state) => state.aplicacion);
  return configurations?.find((item) => item.idVariable === idVar);
};

export const variables = {
  DiasParaReabrirTurnosLiq: 1,
  MostrarDenominacionesEnLiq: 2,
  VolumenMaximoTotalizador: 3,
  VolumenMaximoDiferenciaEnLiq: 4,
  TipoPagoEfectivo: 5,
  FaltanteSobrante: 6,
};
