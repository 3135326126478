import { getStation } from "../../authService";
import api from "../service";

const _controller = "tipopago/servicios";

export async function GetTiposPago(parametros) {
  let params = { idEstacion: await getStation(), ...parametros };
  const res = await api.get(`${_controller}/all`, { params });
  return res.data;
}

export default {
  GetTiposPago,
};
