import { formatoReporte } from "../../../utils/enums";
import api from "../service";

const _impresion = "reportesliq/reportes";

export async function Preview(params) {
  const res = await api.post(`${_impresion}/preview`, params, { responseType: "arraybuffer" });
  return res.data;
}

export async function Export(params) {
  const res = await api.post(`${_impresion}/export?id=${params.id}`, null, { responseType: "arraybuffer" });
  return res.data;
}

export async function ExportReporteRangoFecha(params) {
  let _url = "";
  if (params.formato === formatoReporte.XLS)
    _url = `${_impresion}/reporteRangoFechaXLS?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&productosSinVentas=${params.productosSinVentas}`;
  else
    _url = `${_impresion}/reporteRangoFechaPDF?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&productosSinVentas=${params.productosSinVentas}`;

  const res = await api.post(_url, null, { responseType: "arraybuffer" });
  return res.data;
}

export async function ExportReporteVentasPorCombustible(params) {
  let _url = "";
  if (params.formato === formatoReporte.XLS)
    _url = `${_impresion}/resumenVentasCombustibleXLS?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&mostrarIEPS=${params.mostrarIEPS}`;
  else
    _url = `${_impresion}/resumenVentasCombustiblePDF?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&mostrarIEPS=${params.mostrarIEPS}`;

  const res = await api.post(_url, null, { responseType: "arraybuffer" });
  return res.data;
}

export async function ExportReporteVentasPorDespachador(params) {
  let _url = "";
  if (params.formato === formatoReporte.XLS)
    _url = `${_impresion}/resumenVentasPorDespachadorXLS?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&porCombustible=${params.porCombustible}&noTurno=${params.noTurno}&noIsla=${params.noIsla}`;
  else
    _url = `${_impresion}/resumenVentasPorDespachadorPDF?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&porCombustible=${params.porCombustible}&noTurno=${params.noTurno}&noIsla=${params.noIsla}`;

  const res = await api.post(_url, null, { responseType: "arraybuffer" });
  return res.data;
}

export async function ExportReporteFaltantesYSobrantes(params) {
  let _url = "";
  if (params.formato === formatoReporte.XLS)
    _url = `${_impresion}/reporteFaltantesSobrantesXLS?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}`;
  else _url = `${_impresion}/reporteFaltantesSobrantesPDF?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}`;

  const res = await api.post(_url, null, { responseType: "arraybuffer" });
  return res.data;
}

export default {
  Preview,
  Export,
  ExportReporteRangoFecha,
  ExportReporteVentasPorCombustible,
  ExportReporteVentasPorDespachador,
  ExportReporteFaltantesYSobrantes,
};
