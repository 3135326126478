import { Lock, LockOpen } from "@mui/icons-material";
import { Box, Grid, Hidden } from "@mui/material";
import { UseTurnoContext } from "../../../../contexts";
import { ActionCell } from "./actionCell";
import { OptionsPopup } from "./optionsPopup";
import { useWidth } from "../../../../hooks";

const gridRoot = {
  borderTopStyle: "solid",
  borderWidth: 1,
  borderColor: "lightgray",
};

const TurnoRow = ({ turno }) => {
  const { ultimo } = UseTurnoContext();
  const deletedDisabled = turno.id !== ultimo?.id;

  const screen = useWidth();

  return (
    <Grid item sx={gridRoot}>
      <Box display='flex'>
        <Box p={0.5}>
          {turno.estado ? (
            <LockOpen fontSize='small' color='primary' style={{ verticalAlign: "text-bottom" }} />
          ) : (
            <Lock fontSize='small' color='primary' style={{ verticalAlign: "text-bottom" }} />
          )}
          {screen === "xs" ? `T${turno.noTurno}` : `Turno ${turno.noTurno}`}
        </Box>

        {/* <Hidden only='xs'> */}
        <Box p={0.5} m={0.25} flexGrow={1}>
          {turno.nombreUsuario}
        </Box>
        {/* </Hidden> */}

        <Box p={0.5}>
          <OptionsPopup turno={turno} deletedDisabled={deletedDisabled} trigger={<ActionCell />} />
        </Box>
      </Box>
    </Grid>
  );
};

export default TurnoRow;
