import api from "../service";

const _controller = "turnoliquidacion/servicios";

export async function GetAgrupadoFecha(params) {
  const res = await api.get(`${_controller}/selectGrupoFecha`, {
    params,
  });
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(`${_controller}`, params);
  return res.data;
}

export async function GetUltimo() {
  const res = await api.get(`${_controller}/selectUltimo`);
  return res.data;
}

export async function GetUltimoAbierto() {
  const res = await api.get(`${_controller}/selectUltimoAbierto`);
  return res.data;
}

export async function SetOpen(params) {
  const res = await api.put(`${_controller}/updateAbrir`, null, { params });
  return res.data;
}

export async function SetClose(params) {
  const res = await api.put(`${_controller}/updateCerrar`, null, { params });
  return res.data;
}

export async function Delete(params) {
  const res = await api.delete(`${_controller}`, { params });
  return res.data;
}

export default {
  GetAgrupadoFecha,
  GetUltimo,
  GetUltimoAbierto,
  SetOpen,
  SetClose,
  Insert,
  Delete,
};
