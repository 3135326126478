import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UpdateWorkStation } from "../../api/services/consultas/usuarios";
import { hasErrorRedux } from "../../api/services/service";

export const UpdateEstacionTrabajo = createAsyncThunk("usuarioEstacion/UpdateWorkStation",async (params, { rejectWithValue }) => {
  try {
    return await UpdateWorkStation(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

const initialState = {
  error: {},
};

const slice = createSlice({
  name: "usuarioEstacion",
  initialState,
  reducers:
  {
    clearErrors: (state) => {
        state.error = {};
      },
  },
  extraReducers: (builder) => {
    builder.addCase(UpdateEstacionTrabajo.pending, (state) => {});
    builder.addCase(UpdateEstacionTrabajo.fulfilled, (state) => {
      state.error = {};
    });
    builder.addCase(UpdateEstacionTrabajo.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default slice.reducer;
export const {
    clearErrors
} = slice.actions;