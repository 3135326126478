import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { decimalVal } from "@paul-igas/igas-react-hooks";

const CellHead = styled(TableCell)(() => ({ fontWeight: "bold" }));

export const ListadoPreciosDetalle = ({ detalle }) => {
  var totales = {
    volumen: detalle.reduce((a, b) => {
      return a + b.diferencia;
    }, 0),
    importe: detalle.reduce((a, b) => {
      return a + b.diferencia;
    }, 0),
  };

  return (
    <Card variant='outlined' sx={{ mt: 2, minHeight: 150, overflow: "auto" }}>
      <CardHeader subheader='Precios de venta' sx={{ mt: -1, mb: -1 }} />
      <CardContent sx={{ p: 0, mb: -5 }}>
        <TableContainer>
          <Table size='small' padding='checkbox'>
            <TableHead>
              <TableRow>
                <CellHead align='right'>Volumen</CellHead>
                <CellHead align='right'>Precio</CellHead>
                <CellHead align='right'>Importe</CellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {detalle.map((d) => {
                return (
                  <TableRow>
                    <TableCell align='right'>{decimalVal(3).format(d.diferencia)}</TableCell>
                    <TableCell align='right'>{decimalVal(3).format(d.precioCombustible)}</TableCell>
                    <TableCell align='right'>{decimalVal(3).format(d.importe)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CellHead variant='head' align='right'>
                  {decimalVal(3).format(totales.volumen)}
                </CellHead>
                <CellHead variant='head' align='center'>
                  -
                </CellHead>
                <CellHead variant='head' align='right'>
                  {decimalVal(3).format(totales.importe)}
                </CellHead>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ListadoPreciosDetalle;
