import api from "../service";

const _controller = "denominacion/servicios";

export async function GetDenominaciones() {
  let params = { activo: true };
  const res = await api.get(`${_controller}/selectAll`, { params });
  return res.data;
}

export default {
  GetDenominaciones,
};
