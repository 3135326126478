import { Paper } from "@mui/material";
import { ColumnAlign, ColumnType, LoadingPanel, SimpleTable } from "@paul-igas/igas-react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDenominaciones } from "../../../../store/operaciones/liquidaciones";
import { hasDispatchError } from "../../../../api/services/service";
import { useModal } from "@paul-igas/igas-react-hooks";
import DenomEditDialog from "./dialogs/denomEditDialog";
import { OptionTableRow, TableDenominacionesMobile } from "./components";

const TABLE_DENOMINACIONES = {
  columns: [
    {
      text: "Valor",
      name: "valor",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Cant.",
      name: "cantidad",
      colType: ColumnType.Numeric,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Importe",
      name: "importe",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "",
      name: "action",
      options: true,
      icon: true,
    },
  ],
};

export const DenominacionesPagos = (props) => {
  const {
    denominaciones,
    denominacion,
    screen,
    editDenomDialog,
    loadingDenominaciones,
    openEditDenomDialog,
    onSubmitEditDenomDialog,
  } = useDenominaciones(props);

  const isMobile = screen === "xs" || screen === "sm";

  return (
    <Paper>
      {loadingDenominaciones && isMobile && <LoadingPanel />}

      {!loadingDenominaciones && isMobile && (
        <TableDenominacionesMobile lista={denominaciones} onEdit={openEditDenomDialog} screen={screen} />
      )}

      {!isMobile && (
        <SimpleTable
          table={TABLE_DENOMINACIONES}
          data={denominaciones}
          loading={loadingDenominaciones}
          showPagination={false}
          rowOptions={<OptionTableRow onEdit={openEditDenomDialog} esDenominacion={true} />}
        />
      )}

      <DenomEditDialog
        open={editDenomDialog.isShowing}
        onClose={editDenomDialog.close}
        postSubmit={onSubmitEditDenomDialog}
        data={denominacion}
      />
    </Paper>
  );
};

export default DenominacionesPagos;

const useDenominaciones = (props) => {
  const { denominaciones, onUpdate, screen } = props;
  const { loading } = useSelector((state) => state.liquidaciones);
  const dispatch = useDispatch();
  const [denominacion, setDenominacion] = useState({});

  const editDenomDialog = useModal(false);

  const openEditDenomDialog = (row) => {
    setDenominacion(row);
    editDenomDialog.open();
  };

  const onSubmitEditDenomDialog = (newRow) => {
    const indexD = denominaciones.findIndex((d) => d.id === newRow.id);
    let tmpD = denominaciones.slice();
    tmpD[indexD] = newRow;
    onUpdate(tmpD);
  };

  const asignaDenominaciones = ({ payload }) => {
    let _tmpDenoms = payload.map((d) => {
      return { ...d, cantidad: 0, importe: 0 };
    });
    _tmpDenoms.sort((a, b) => b.id - a.id);
    onUpdate(_tmpDenoms);
  };

  const initLista = () => {
    if ((denominaciones?.length ?? 0) <= 0) {
      dispatch(getDenominaciones())
        .then(hasDispatchError)
        .then(asignaDenominaciones)
        .catch(() => {});
    }
  };

  useEffect(initLista, []);

  return {
    denominaciones,
    editDenomDialog,
    denominacion,
    screen,
    loadingDenominaciones: loading.getDenominaciones,
    openEditDenomDialog,
    onSubmitEditDenomDialog,
  };
};
