import { Grid } from "@mui/material";
import { InfoCard, InfoDetailCard, InfoTitle } from "../../igas";
import { formatDateTimeMX, monedaMX, zeroPad } from "@paul-igas/igas-react-hooks";

export const TurnoInfo = (props) => {
  const { loading, liquidaciones, turno, screen } = props;

  return (
    <Grid container spacing={2}>
      {/* turno */}
      <Grid item xs={12} sm={5}>
        <InfoCard
          loading={loading}
          title={<InfoTitle caption='Turno' />}
          info={
            <InfoTitle
              color='primary'
              variant='h3'
              caption={
                //Se cambia el NoTurno en caso de que se haya precargado
                //un turno específico desde la pantalla de turnos
                zeroPad(turno?.noTurno, 2)
              }
            />
          }
          detail={
            <>
              <InfoDetailCard
                title={<InfoTitle variant='body2' caption='Fecha:' bold />}
                value={
                  <InfoTitle
                    variant='body2'
                    caption={
                      //Misma validación de turno con la fecha si viene precargada
                      //de la pantalla de turnos.
                      formatDateTimeMX(turno?.fecha ?? new Date())
                    }
                  />
                }
              />

              <InfoDetailCard
                title={<InfoTitle variant='body2' caption='Cajero:' bold />}
                value={
                  <InfoTitle
                    variant='body2'
                    caption={
                      //Misma validación de turno con la fecha si viene precargada
                      //de la pantalla de turnos.
                      turno?.nombreUsuario ?? ""
                    }
                  />
                }
              />

              {/* {screen === "sm" && (
                <InfoDetailCard
                  title={<InfoTitle variant='body2' caption='&nbsp;' bold />}
                  value={<InfoTitle variant='body2' caption='&nbsp;' />}
                />
              )} */}
            </>
          }
        />
      </Grid>
      {/* liquidaciones */}
      <Grid item xs={12} sm={7}>
        <InfoCard
          loading={loading.getAll}
          title={<InfoTitle caption='Total' />}
          info={
            <InfoTitle
              color='primary'
              variant='h3'
              caption={monedaMX.format(liquidaciones?.reduce((a, b) => a + b.totalGeneral, 0))}
            />
          }
          detail={
            <>
              <InfoDetailCard
                title={
                  <InfoTitle
                    variant='body2'
                    caption={screen === "sm" ? "Combustibles:" : "Total de Combustibles:"}
                    bold
                  />
                }
                value={
                  <InfoTitle
                    variant='body2'
                    caption={monedaMX.format(liquidaciones?.reduce((a, b) => a + b.totalCombustible, 0))}
                  />
                }
              />
              <InfoDetailCard
                title={
                  <InfoTitle
                    variant='body2'
                    caption={screen === "sm" ? "Prod. Diversos:" : "Total de Prod. Diversos:"}
                    bold
                  />
                }
                value={
                  <InfoTitle
                    variant='body2'
                    caption={monedaMX.format(liquidaciones?.reduce((a, b) => a + b.totalDiversos, 0))}
                  />
                }
              />
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default TurnoInfo;
