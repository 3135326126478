import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { DetailAlign, DetailDataType, DetailInfo } from "@paul-igas/igas-react-components";

export const ProductoDetailDialog = ({ open, onClose, data }) => {
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>Detalle de producto</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{data?.descripcion}</DialogContentText>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DetailInfo
              title='Inv. Inicial'
              description={data.inventarioInicial}
              dataType={DetailDataType.Numeric}
              dataAlign={DetailAlign.Right}
            />
          </Grid>

          <Grid item xs={4}>
            <DetailInfo
              title='Entradas'
              description={data.entradas}
              dataType={DetailDataType.Numeric}
              dataAlign={DetailAlign.Right}
            />
          </Grid>

          <Grid item xs={4}>
            <DetailInfo
              title='Devolución'
              description={data.devolucion}
              dataType={DetailDataType.Numeric}
              dataAlign={DetailAlign.Right}
            />
          </Grid>

          <Grid item xs={4}>
            <DetailInfo
              title='Inv. Final'
              description={data.inventarioFinal}
              dataType={DetailDataType.Numeric}
              dataAlign={DetailAlign.Right}
            />
          </Grid>

          <Grid item xs={4}>
            <DetailInfo
              title='Venta'
              description={data.venta}
              dataType={DetailDataType.Numeric}
              dataAlign={DetailAlign.Right}
            />
          </Grid>

          <Grid item xs={4}>
            <DetailInfo
              title='Precio'
              description={data.precioProducto}
              dataType={DetailDataType.Money}
              dataAlign={DetailAlign.Right}
            />
          </Grid>

          <Grid item xs={6}>
            <DetailInfo
              title='Importe'
              description={data.importe}
              dataType={DetailDataType.Money}
              dataAlign={DetailAlign.Right}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductoDetailDialog;
