import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import {
  GetVentasCombustibles,
  GetResumenVentasDespachador,
  GetResumenVentasDiversos,
  GetAlertas,
  GetVentasCombustiblesDetalle,
} from "../../api/services/consultas/dashboard";

export const getVentasCombustibles = createAsyncThunk(
  "liquidacion/getVentasCombustibles",
  async (params, { rejectWithValue }) => {
    try {
      return await GetVentasCombustibles(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getVentasCombustiblesDetalle = createAsyncThunk(
  "liquidacion/getVentasCombustiblesDetalle",
  async (params, { rejectWithValue }) => {
    try {
      return await GetVentasCombustiblesDetalle(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getResumenVentasDiversos = createAsyncThunk(
  "liquidacion/getResumenVentasDiversos",
  async (params, { rejectWithValue }) => {
    try {
      return await GetResumenVentasDiversos(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getResumenVentasDespachador = createAsyncThunk(
  "liquidacion/getResumenVentasDespachador",
  async (params, { rejectWithValue }) => {
    try {
      return await GetResumenVentasDespachador(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getAlertas = createAsyncThunk("liquidacion/getAlertas", async (params, { rejectWithValue }) => {
  try {
    return await GetAlertas(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  ventasCombustibles: [],
  ventasCombustiblesDet: [],
  ventasDiversos: [],
  ventasDespachador: [],
  alertas: [],
  error: {},
  loading: {
    getVentasCombustibles: false,
    getVentasCombustiblesDetalle: false,
    getResumenVentasDiversos: false,
    getResumenVentasDespachador: false,
    getAlertas: false,
  },
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVentasCombustibles.pending, (state) => {
      state.loading.getVentasCombustibles = true;
    });
    builder.addCase(getVentasCombustibles.fulfilled, (state, action) => {
      state.ventasCombustibles = action.payload;
      state.error = {};
      state.loading.getVentasCombustibles = false;
    });
    builder.addCase(getVentasCombustibles.rejected, (state, action) => {
      state.error = action.payload;
      state.ventasCombustibles = [];
      state.loading.getVentasCombustibles = false;
    });

    builder.addCase(getVentasCombustiblesDetalle.pending, (state) => {
      state.loading.getVentasCombustiblesDetalle = true;
    });
    builder.addCase(getVentasCombustiblesDetalle.fulfilled, (state, action) => {
      state.ventasCombustiblesDet = action.payload;
      state.error = {};
      state.loading.getVentasCombustiblesDetalle = false;
    });
    builder.addCase(getVentasCombustiblesDetalle.rejected, (state, action) => {
      state.error = action.payload;
      state.ventasCombustiblesDet = [];
      state.loading.getVentasCombustiblesDetalle = false;
    });

    builder.addCase(getResumenVentasDiversos.pending, (state) => {
      state.loading.getResumenVentasDiversos = true;
    });
    builder.addCase(getResumenVentasDiversos.fulfilled, (state, action) => {
      state.ventasDiversos = action.payload;
      state.error = {};
      state.loading.getResumenVentasDiversos = false;
    });
    builder.addCase(getResumenVentasDiversos.rejected, (state, action) => {
      state.error = action.payload;
      state.ventasDiversos = [];
      state.loading.getResumenVentasDiversos = false;
    });

    builder.addCase(getResumenVentasDespachador.pending, (state) => {
      state.loading.getResumenVentasDespachador = true;
    });
    builder.addCase(getResumenVentasDespachador.fulfilled, (state, action) => {
      state.ventasDespachador = action.payload;
      state.error = {};
      state.loading.getResumenVentasDespachador = false;
    });
    builder.addCase(getResumenVentasDespachador.rejected, (state, action) => {
      state.error = action.payload;
      state.ventasDespachador = [];
      state.loading.getResumenVentasDespachador = false;
    });

    builder.addCase(getAlertas.pending, (state) => {
      state.loading.getAlertas = true;
    });
    builder.addCase(getAlertas.fulfilled, (state, action) => {
      state.alertas = action.payload;
      state.error = {};
      state.loading.getAlertas = false;
    });
    builder.addCase(getAlertas.rejected, (state, action) => {
      state.error = action.payload;
      state.alertas = [];
      state.loading.getAlertas = false;
    });
  },
});

export default slice.reducer;
export const { clearErrors } = slice.actions;
