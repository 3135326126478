import { useEffect } from "react";
import { Select, Grid, Paper, MenuItem, Alert, AlertTitle } from "@mui/material";
import { FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { getIslas, getDespachadores } from "../../../../store/operaciones/liquidaciones";
import { LoadingPanel } from "@paul-igas/igas-react-components";
import { variables } from "../../../helpers/variablesEstacion";
import { useTipoPagoValido } from "../hooks";

export function GeneralStep({ data }) {
  const { islas, despachadores, loading, TipoPagoEfectivo, changeIsla, changeDespachador } = useGeneralStep(data);

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Paper style={{ padding: 24, marginTop: "8px" }}>
          {loading && <LoadingPanel title='Cargando datos generales...' />}
          {!loading && TipoPagoEfectivo < 0 && (
            <Alert severity='error'>
              <AlertTitle>Configuración</AlertTitle>
              No se ha configurado el tipo de pago Efectivo para la liquidación; consulte con su administrador de
              sistema.
            </Alert>
          )}
          {!loading && TipoPagoEfectivo > 0 && (
            <Grid container spacing={2}>
              {!data.LIQGLOBAL && (
                <Grid item xs={12} sm={5} md={3}>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='select-isla-label'>Seleccione una isla</InputLabel>
                    <Select
                      variant='standard'
                      labelId='select-isla-label'
                      id='select-isla'
                      value={data.isla?.id ?? ""}
                      onChange={changeIsla}
                      error={data.isla?.liquidada}
                      MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                    >
                      {islas?.length === 0 && (
                        <MenuItem key={""} value={""}>
                          <span>Vacio</span>
                        </MenuItem>
                      )}
                      {islas?.map((isla) => (
                        <MenuItem key={isla?.id} value={isla?.id}>
                          Isla {isla?.isla}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>{data.isla?.liquidada && "Isla se encuentra liquidada."}</FormHelperText>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={7} md={4}>
                <FormControl fullWidth size='small'>
                  <InputLabel id='select-despachador-label'>Seleccione un despachador</InputLabel>
                  <Select
                    variant='standard'
                    labelId='select-despachador-label'
                    id='select-despachador'
                    value={data.despachador?.id ?? ""}
                    onChange={changeDespachador}
                  >
                    {despachadores?.length === 0 && (
                      <MenuItem key={""} value={""}>
                        <span>{`<<Vacío>>`}</span>
                      </MenuItem>
                    )}
                    {despachadores?.map((despachador) => (
                      <MenuItem key={despachador?.id} value={despachador?.id}>
                        {despachador?.nombreCompleto}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const useGeneralStep = (data) => {
  const dispatch = useDispatch();
  const { islas, despachadores, loading } = useSelector((state) => state.liquidaciones);
  const { valorVariable } = useTipoPagoValido(variables.TipoPagoEfectivo);
  const { asignaIsla, asignaDespachador, desactivaStepBtn, activaStepBtn } = data;
  const isLoading = loading.getIslas || loading.getCajeros || loading.getTiposPago;
  const tpEfectivo = valorVariable;

  const asignaIslaById = (id) => {
    const result = islas?.find((e) => e.id === id);
    asignaIsla({
      ...result,
      liquidada: false,
    });
  };

  const asignaDespachadorById = (id) => {
    const result = despachadores?.find((e) => e.id === id);
    asignaDespachador(result);
  };

  const inicializaIsla = ({ payload }) => {
    data.LIQGLOBAL
      ? asignaIsla({
          id: 0,
          isla: 0,
        })
      : asignaIsla(payload?.length > 0 ? payload[0] : null);
  };

  const inicializaDespachador = ({ payload }) => {
    asignaDespachador(payload?.length > 0 ? payload[0] : null);
  };

  const initStep = () => {
    dispatch(getIslas())
      .then(hasDispatchError)
      .then(inicializaIsla)
      .catch(() => {});
    dispatch(getDespachadores())
      .then(hasDispatchError)
      .then(inicializaDespachador)
      .catch(() => {});
  };

  useEffect(initStep, []);
  useEffect(() => {
    isLoading || tpEfectivo < 0 ? desactivaStepBtn() : activaStepBtn();
  }, [isLoading, tpEfectivo]);

  return {
    islas,
    despachadores,
    loading: isLoading,
    TipoPagoEfectivo: tpEfectivo,
    changeIsla: (e) => asignaIslaById(e.target.value),
    changeDespachador: (e) => asignaDespachadorById(e.target.value),
  };
};
