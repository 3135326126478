import { getSubscription } from "../../authService";
import api from "../service";

const _controller = "usuarioEstacion/servicios";

export async function GetStations(id) {
  const params = { id: id };
  const res = await api.get(`${_controller}/SelectStations`, { params });
  return res.data;
}

export async function UpdateWorkStation(params) {
  const res = await api.put(`${_controller}/updateWorkStation`, null, { params });
  return res.data;
}

export async function GetDespachadores() {
  let params = { roles: "Despachador", suscripcion: await getSubscription() };
  const res = await api.get(`${_controller}/SelectByRoleEstacion`, { params });
  return res.data;
}

export async function GetCajeros() {
  let params = { roles: "Cajero", suscripcion: await getSubscription() };
  const res = await api.get(`${_controller}/SelectByRoleEstacion`, { params });
  return res.data;
}

export default {
  GetDespachadores,
  GetCajeros,
  UpdateWorkStation,
  GetStations,
};
