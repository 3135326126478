import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import {
  ExportReporteFaltantesYSobrantes,
  ExportReporteRangoFecha,
  ExportReporteVentasPorCombustible,
  ExportReporteVentasPorDespachador,
} from "../../api/services/reportes/liquidaciones";

export const resumenPorRangoFechas = createAsyncThunk(
  "liquidacion/resumenPorRangoFechas",
  async (params, { rejectWithValue }) => {
    try {
      return await ExportReporteRangoFecha(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const resumenDeVentasPorCombustible = createAsyncThunk(
  "liquidacion/resumenDeVentasPorCombustible",
  async (params, { rejectWithValue }) => {
    try {
      return await ExportReporteVentasPorCombustible(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const resumenDeVentasPorDespachador = createAsyncThunk(
  "liquidacion/resumenDeVentasPorDespachador",
  async (params, { rejectWithValue }) => {
    try {
      return await ExportReporteVentasPorDespachador(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const resumenFaltantesYSobrantes = createAsyncThunk(
  "liquidacion/resumenFaltantesYSobrantes",
  async (params, { rejectWithValue }) => {
    try {
      return await ExportReporteFaltantesYSobrantes(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

const initialState = { error: {}, nombreReporte: "" };

const slice = createSlice({
  name: "reportesLiq",
  initialState,
  reducers: {
    setNombreReporte: (state, action) => {
      state.nombreReporte = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumenPorRangoFechas.fulfilled, (state) => {
      state.error = {};
    });
    builder.addCase(resumenPorRangoFechas.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(resumenDeVentasPorCombustible.fulfilled, (state) => {
      state.error = {};
    });
    builder.addCase(resumenDeVentasPorCombustible.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(resumenFaltantesYSobrantes.fulfilled, (state) => {
      state.error = {};
    });
    builder.addCase(resumenFaltantesYSobrantes.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(resumenDeVentasPorDespachador.fulfilled, (state) => {
      state.error = {};
    });
    builder.addCase(resumenDeVentasPorDespachador.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default slice.reducer;
export const { clearErrors, setNombreReporte } = slice.actions;
