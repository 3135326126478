import { PieChart } from "@mui/x-charts";
import { Parametros } from "../configuracion";
import { decimalVal, monedaMX } from "@paul-igas/igas-react-hooks";

export const EmptyPie = ({ msg = "", pUni }) => {
  const ventas = [{ id: 0, value: 1, label: `${msg}`, color: "#e6e5e2" }];

  const margins = { top: 5, bottom: 20, left: 50, right: 50 };
  const outerRadius = 100;

  return (
    <PieChart
      margin={margins}
      series={[
        {
          data: ventas,
          valueFormatter:
            pUni === Parametros.importe
              ? (ventas) => monedaMX.format(0)
              : (ventas) => `${new decimalVal(0).format(0)} (lts)`,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          innerRadius: 60,
          outerRadius,
          paddingAngle: 2,
          cornerRadius: 5,
          startAngle: 0,
          endAngle: 360,
        },
      ]}
      slotProps={{
        legend: { hidden: true },
      }}
    />
  );
};

export default EmptyPie;
