import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ColumnAlign,
  ColumnType,
  DataTable,
  ExportDialog,
  ToolBarIGas,
  DangerMessage,
  WarningMessage,
  ColumnPriority,
  LoadingPanel,
} from "@paul-igas/igas-react-components";
import { getUltimoAbierto } from "../../../store/operaciones/turnos";
import { hasDispatchError } from "../../../api/services/service";
import { Alert, AlertTitle, Grid, Button, Tooltip } from "@mui/material";
import { useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";
import {
  clearErrors,
  exportToPDF,
  selectAll,
  setIdLiquidacion,
  setJarreoConsignacionModificados,
  setLecturaProductoModificado,
  setProductoModificado,
} from "../../../store/operaciones/liquidaciones";
import {
  DeleteDialog,
  IslasEnCerosDialog,
  OptionTableRow,
  TurnoInfo,
} from "../../../components/operaciones/liquidacion";
import { showInWindow } from "../../../components/helpers/documents";
import { VpnLockOutlined } from "@mui/icons-material";
import { useWidth } from "../../../hooks";

const esLiqGlobal = (value) =>
  value.esGlobal ? (
    <Tooltip title='Liquidación Global'>
      <VpnLockOutlined fontSize='small' />
    </Tooltip>
  ) : (
    zeroPad(value.noIsla, 3)
  );

const TABLE_DEFAULT = {
  columns: [
    { text: "Isla", name: "_", icon: true, colAlign: ColumnAlign.Center, renderCell: esLiqGlobal },
    { text: "Folio", name: "folio", colType: ColumnType.DataKey, colAlign: ColumnAlign.Center, digitos: 6 },
    { text: "Despachador", name: "nombreDespachador", colPriority: ColumnPriority.Normal },
    {
      text: "Combustible",
      name: "totalCombustible",
      colPriority: ColumnPriority.Low,
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Prod. Diversos",
      name: "totalDiversos",
      colPriority: ColumnPriority.Low,
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Total",
      name: "totalGeneral",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function Liquidaciones() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notTurno, turno, turnoLoading } = GetTurno();
  const { loading, liquidaciones, turnoActual, error } = useSelector((state) => state.liquidaciones);
  const [liqGlobal, setLiqGlobal] = useState(false);
  const screen = useWidth();
  const modalDelete = useModal(false);
  const modalExport = useModal(false);
  const modalIslas = useModal(false);
  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);

  const handleSelect = (liq) => {
    dispatch(setIdLiquidacion(liq));
  };

  const handleinsert = () => {
    navigate("/operaciones/liquidaciones/agregar");
  };

  const handleEdit = () => {
    navigate("/operaciones/liquidaciones/editar");
  };

  const handleGlobal = () => {
    navigate("/operaciones/liquidaciones/global");
  };

  const handleExport = (id) => {
    modalExport.open();
    let params = { id: id };
    dispatch(exportToPDF(params))
      .then(hasDispatchError)
      .then(showInWindow)
      .then(modalExport.close)
      .catch(modalExport.close);
  };

  const verificaGlobal = () => {
    let _global = liquidaciones?.some((x) => x.esGlobal === true);
    setLiqGlobal(_global);
  };

  const handleRefresh = () => {
    //Se consulta en base al identificador del id del turno seleccionado
    //en la pantalla de turnos, si no contiene elementos, se consulta
    //el turno normalmente.
    if (Object.keys(turnoActual).length !== 0) {
      dispatch(selectAll({ idTurno: turnoActual?.id }))
        .then(hasDispatchError)
        .catch(() => {});
    } else if (!notTurno && !turnoLoading) {
      dispatch(selectAll({ idTurno: turno?.id }))
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleRefresh, [notTurno, turnoLoading]);
  useEffect(handleError, [error]);
  useEffect(verificaGlobal, [liquidaciones]);

  if (turnoLoading) return <LoadingPanel title='Cargando...' size={100} />;

  return (
    <>
      <ToolBarIGas
        title='Liquidaciones del turno'
        loading={false}
        onRefresh={!notTurno && !turnoLoading ? handleRefresh : () => {}}
        onInsert={!notTurno && !turnoLoading && liqGlobal !== true ? handleinsert : null}
      />
      {notTurno && !turnoLoading && (
        <Alert severity='info'>
          <AlertTitle>No se encontró turno abierto</AlertTitle>
          Actualmente no se encuentra un turno abierto en la estación de trabajo; es necesario contar con uno para
          operar en liquidaciones.
        </Alert>
      )}

      {(notTurno && !turnoLoading) || (
        <Grid container spacing={1} justifyContent='flex-end' sx={{ pb: 1 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              onClick={handleGlobal}
              variant='outlined'
              disabled={loading.getAll || liquidaciones.length > 0}
            >
              Liquidación global del turno
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              onClick={modalIslas.open}
              variant='outlined'
              disabled={loading.getAll || liqGlobal === true}
            >
              Liquidar islas sin movimientos
            </Button>
          </Grid>
        </Grid>
      )}

      {(notTurno && !turnoLoading) || (
        <Grid container spacing={2}>
          {/* turno */}
          <Grid item xs={12}>
            <TurnoInfo
              loading={turnoLoading}
              turno={Object.keys(turnoActual).length !== 0 ? turnoActual : turno}
              liquidaciones={liquidaciones}
              screen={screen}
            />
          </Grid>

          <Grid item xs={12}>
            <DataTable
              table={TABLE_DEFAULT}
              data={liquidaciones}
              loading={loading.getAll}
              showEmptyRows={false}
              rowOptions={
                <OptionTableRow
                  onEdit={handleEdit}
                  onDelete={modalDelete.open}
                  onSelect={handleSelect}
                  onExport={handleExport}
                />
              }
            />
          </Grid>
        </Grid>
      )}
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <ExportDialog open={modalExport.isShowing} onClose={modalExport.close} />
      <IslasEnCerosDialog
        open={modalIslas.isShowing}
        onClose={modalIslas.close}
        postSubmit={handleRefresh}
        turno={Object.keys(turnoActual).length !== 0 ? turnoActual : turno}
      />

      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}

export default Liquidaciones;

const GetTurno = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.turnos);
  const [turno, setTurno] = useState({});
  const { turnoActual } = useSelector((state) => state.liquidaciones);

  const asignaTurno = ({ payload }) => {
    setTurno(payload);
  };

  const ultimoTurnoAbierto = () => {
    if (turnoActual?.id ?? 0 === 0) {
      dispatch(getUltimoAbierto())
        .then(hasDispatchError)
        .then(asignaTurno)
        .catch(() => {});
      dispatch(setJarreoConsignacionModificados(false));
      dispatch(setLecturaProductoModificado(false));
      dispatch(setProductoModificado(false));
    }
  };

  useEffect(ultimoTurnoAbierto, []);

  if (Object.keys(turnoActual).length !== 0) {
    return {
      notTurno: (turnoActual?.id ?? 0) === 0,
      turno: turnoActual,
      turnoLoading: loading.getUltimoAbierto,
    };
  } else {
    return {
      notTurno: (turno?.id ?? 0) === 0,
      turno,
      turnoLoading: loading.getUltimoAbierto,
    };
  }
};
