import { Alert, Box, Button, Grid, Paper, Typography } from "@mui/material";
import { formatDateTimeMX, monedaMX } from "@paul-igas/igas-react-hooks";

export function ResumenStep({ data }) {
  const {
    turno,
    isla,
    despachador,
    totales,
    onSave,
    lecturaProductoModificado,
    productoModificado,
    onPreview,
    processing,
  } = data;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textSecondary'>
          <strong>Turno: </strong>
          {turno?.noTurno}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          <strong>Fecha: </strong>
          {formatDateTimeMX(turno?.fecha)}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          <strong>Isla: </strong>
          {data.LIQGLOBAL || data.esGlobal ? "TODAS" : isla?.isla}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          <strong>Despachador: </strong>
          {despachador?.nombreCompleto}
        </Typography>
      </Grid>
      {!lecturaProductoModificado && !productoModificado ? null : (
        <Grid item xs={12}>
          <Alert severity='warning'>
            Al modificar las lecturas y/o productos diversos de este turno, se abrirá el turno posterior y puede causar
            un descuadre en las liquidaciones existentes.
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={3}>
            <ConceptoResumen title='Combustibles' importe={totales.combustibles} />
          </Grid>

          <Grid item xs={6} md={3}>
            <ConceptoResumen title='Productos diversos' importe={totales.productos} />
          </Grid>

          <Grid item xs={6} md={3}>
            <ConceptoResumen title='Liquidación' importe={totales.combustibles + totales.productos} />
          </Grid>

          <Grid item xs={6} md={3}>
            <ConceptoResumen title='Pagos' importe={totales.pagos} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button disabled={processing} onClick={onPreview} variant='outlined' style={{ marginRight: 8 }}>
          Vista Previa
        </Button>
        <Button disabled={processing} variant='contained' color='secondary' disableElevation onClick={onSave}>
          Guardar Liquidación
        </Button>
      </Grid>
    </Grid>
  );
}

export default ResumenStep;

const ConceptoResumen = ({ title = "", importe = 0 }) => {
  return (
    <Box>
      <Paper style={{ padding: 16 }}>
        <Typography variant='body2' color='textSecondary' gutterBottom>
          {title}
        </Typography>
        <Typography variant='h5' gutterBottom align='right'>
          {monedaMX.format(importe)}
        </Typography>
      </Paper>
    </Box>
  );
};
