import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { monedaMX } from "@paul-igas/igas-react-hooks";
import OptionTableRow from "./optionTableRow";

const CellHead = styled(TableCell)(() => ({ fontWeight: "bold" }));

export const TableDenominacionesMobile = ({ lista, onEdit, screen }) => {
  return (
    <TableContainer>
      <Table size='small' padding='checkbox'>
        <TableHead>
          <TableRow style={{ backgroundColor: "whitesmoke" }}>
            <CellHead align='right'>Valor</CellHead>
            <CellHead align='right'>{screen === "sm" ? "Cant." : "Cantidad"}</CellHead>
            <CellHead align='right'>Importe</CellHead>
            <CellHead align='center'></CellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista.map((den) => (
            <RowDenominacionMobile key={den.id} rowData={den} onEdit={onEdit} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDenominacionesMobile;

const RowDenominacionMobile = (props) => {
  const { rowData, onEdit } = props;
  return (
    <TableRow>
      <TableCell align='right' padding='none'>
        {monedaMX.format(rowData.valor)}
      </TableCell>
      <TableCell align='right' padding='none'>
        {rowData.cantidad}
      </TableCell>
      <TableCell align='right' padding='none'>
        {monedaMX.format(rowData.importe)}
      </TableCell>
      <TableCell align='center' padding='none'>
        <OptionTableRow data={rowData} onEdit={onEdit} esDenominacion={true} />
      </TableCell>
    </TableRow>
  );
};
