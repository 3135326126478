import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetIslas } from "../../api/services/consultas/dispensarios";
import { GetVentasDiversos } from "../../api/services/consultas/ventasDiversos";
import { GetLecturas } from "../../api/services/consultas/turnoVol";
import { GetDespachadores } from "../../api/services/consultas/usuarios";
import { hasErrorRedux } from "../../api/services/service";
import { GetTiposPago } from "../../api/services/consultas/tiposPago";
import { GetDenominaciones } from "../../api/services/consultas/denominaciones";
import {  Get, GetAll, GetByIslaTurno, Insert } from "../../api/services/operaciones/preliquidaciones";

export const selectAll = createAsyncThunk("preliquidacion/selectAll", async (_, { rejectWithValue }) => {
  try {
    return await GetAll();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const select = createAsyncThunk("preliquidacion/select", async (params, { rejectWithValue }) => {
  try {
    return await Get(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("preliquidacion/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const selectByIslaTurno = createAsyncThunk("preliquidacion/byIslaTurno", async (params, { rejectWithValue }) => {
  try {
    return await GetByIslaTurno(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getIslas = createAsyncThunk("preliquidacion/islas", async (_, { rejectWithValue }) => {
  try {
    return await GetIslas();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getDespachadores = createAsyncThunk("preliquidacion/despachadores", async (_, { rejectWithValue }) => {
  try {
    return await GetDespachadores();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getLecturas = createAsyncThunk("preliquidacion/lecturas", async (params, { rejectWithValue }) => {
  try {
    return await GetLecturas(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getVentasDiversos = createAsyncThunk("preliquidacion/ventasDiversos", async (params, { rejectWithValue }) => {
  try {
    return await GetVentasDiversos(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getTiposPago = createAsyncThunk("preliquidacion/tiposPago", async (params, { rejectWithValue }) => {
  try {
    return await GetTiposPago(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getDenominaciones = createAsyncThunk("preliquidacion/denominaciones", async (_, { rejectWithValue }) => {
  try {
    return await GetDenominaciones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  preliquidaciones: [],
  selectedPreliq: {},
  islas: [],
  despachadores: [],
  loading: {
    getAll: false,
    select: false,
    selectByIslaTurno: false,
    insert: false,
    delete: false,
    update: false,
    getIslas: false,
    getDespachadores: false,
    getLecturas: false,
    getVentasDiversos: false,
    getTiposPago: false,
    getDenominaciones: false,
  },
};

const slice = createSlice({
  name: "preliquidaciones",
  initialState,
  reducers: {
    setIdPreliquidacion: (state, action) => {
      state.selectedPreliq = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // SelectAll
    builder.addCase(selectAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(selectAll.fulfilled, (state, action) => {
      state.preliquidaciones = action.payload;
      state.error = {};
      state.loading.getAll = false;
    });
    builder.addCase(selectAll.rejected, (state, action) => {
      state.error = action.payload;
      state.preliquidaciones = [];
      state.loading.getAll = false;
    });
    // Select
    builder.addCase(select.pending, (state) => {
      state.loading.select = true;
    });
    builder.addCase(select.fulfilled, (state) => {
      state.error = {};
      state.loading.select = false;
    });
    builder.addCase(select.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.select = false;
    });
    // SelectByIslaTurno
    builder.addCase(selectByIslaTurno.pending, (state) => {
      state.loading.selectByIslaTurno = true;
    });
    builder.addCase(selectByIslaTurno.fulfilled, (state) => {
      state.error = {};
      state.loading.selectByIslaTurno = false;
    });
    builder.addCase(selectByIslaTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.selectByIslaTurno = false;
    });
    // Registrar
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    // Islas
    builder.addCase(getIslas.pending, (state) => {
      state.loading.getIslas = true;
    });
    builder.addCase(getIslas.fulfilled, (state, action) => {
      state.error = {};
      state.islas = action.payload;
      state.loading.getIslas = false;
    });
    builder.addCase(getIslas.rejected, (state, action) => {
      state.error = action.payload;
      state.islas = [];
      state.loading.getIslas = false;
    });
    // Despachadores
    builder.addCase(getDespachadores.pending, (state) => {
      state.loading.getDespachadores = true;
    });
    builder.addCase(getDespachadores.fulfilled, (state, action) => {
      state.error = {};
      state.despachadores = action.payload;
      state.loading.getDespachadores = false;
    });
    builder.addCase(getDespachadores.rejected, (state, action) => {
      state.error = action.payload;
      state.despachadores = [];
      state.loading.getDespachadores = false;
    });
    // Lecturas
    builder.addCase(getLecturas.pending, (state) => {
      state.loading.getLecturas = true;
    });
    builder.addCase(getLecturas.fulfilled, (state) => {
      state.error = {};
      state.loading.getLecturas = false;
    });
    builder.addCase(getLecturas.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getLecturas = false;
    });
    // Ventas diversos
    builder.addCase(getVentasDiversos.pending, (state) => {
      state.loading.getVentasDiversos = true;
    });
    builder.addCase(getVentasDiversos.fulfilled, (state) => {
      state.error = {};
      state.loading.getVentasDiversos = false;
    });
    builder.addCase(getVentasDiversos.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getVentasDiversos = false;
    });
    // Tipos pago
    builder.addCase(getTiposPago.pending, (state) => {
      state.loading.getTiposPago = true;
    });
    builder.addCase(getTiposPago.fulfilled, (state) => {
      state.error = {};
      state.loading.getTiposPago = false;
    });
    builder.addCase(getTiposPago.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getTiposPago = false;
    });
    // Denominaciones
    builder.addCase(getDenominaciones.pending, (state) => {
      state.loading.getDenominaciones = true;
    });
    builder.addCase(getDenominaciones.fulfilled, (state) => {
      state.error = {};
      state.loading.getDenominaciones = false;
    });
    builder.addCase(getDenominaciones.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getDenominaciones = false;
    });
  },
});

export default slice.reducer;
export const { setIdPreliquidacion, clearErrors } = slice.actions;
