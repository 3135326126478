import api from "../service";

const _controller = "turnovolumetrico/servicios";

export async function GetLecturas(params) {
  const res = await api.get(`${_controller}/selectLecturasIslaTurno`, { params });
  return res.data;
};

export async function GetIslasEnCeros(params){
  const res = await api.get(`${_controller}/selectIslasEnCeros`, { params });
  return res.data;
};

export async function GetByGlobalTurno(params) {
  const res = await api.get(`${_controller}/selectLecturasGlobalTurno`, { params });
  return res.data;
}

export default {
  GetLecturas,
  GetIslasEnCeros,
  GetByGlobalTurno
};
