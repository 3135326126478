import { Grid, Paper, Typography } from "@mui/material";
import { monedaMX } from "@paul-igas/igas-react-hooks";

export const TotalesPagos = ({ totales, diferencia }) => {
  return (
    <Grid container direction={Math.abs(diferencia) > 0 ? "column" : "row"} spacing={2}>
      <Grid item xs={12} sm={Math.abs(diferencia) > 0 ? 6 : 12} lg={12}>
        <Paper style={{ padding: 16 }}>
          <Typography variant='body2' color='InactiveCaptionText' gutterBottom>
            Total de liquidación
          </Typography>
          <Typography variant='h5' align='right'>
            {monedaMX.format(totales.combustibles + totales.productos)}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={Math.abs(diferencia) > 0 ? 6 : 12} lg={12}>
        <Paper style={{ padding: 16 }}>
          <Typography variant='body2' color='InactiveCaptionText' gutterBottom>
            Total de pagos
          </Typography>
          <Typography variant='h5' align='right'>
            {monedaMX.format(totales.pagos ?? 0)}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TotalesPagos;
