import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Parametros } from "./configuracion";

export const MenuDashboard = ({ onPeriodoChange, onUnidadChange, periodo, unidad }) => {  

  const handlePeriodoChange = (event, newPeriodo) => {
    if (newPeriodo !== null) {     
      onPeriodoChange(newPeriodo);
    }
  };

  const handleUnidadChange = (event, newUnidad) => {
    if (newUnidad !== null) {      
      onUnidadChange(newUnidad);
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "16px" }}>
        <ToggleButtonGroup
          color='primary'
          value={periodo}
          exclusive
          onChange={handlePeriodoChange}
          aria-label='Periodo'
        >
          <ToggleButton value={Parametros.mes}>Mes</ToggleButton>
          <ToggleButton value={Parametros.anio}>Año</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup color='primary' value={unidad} exclusive onChange={handleUnidadChange} aria-label='Unidad'>
          <ToggleButton value={Parametros.importe}>Importe</ToggleButton>
          <ToggleButton value={Parametros.litros}>Litros</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </>
  );
};
