import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DangerMessage,
  ExportDialog,
  IGasCheckboxField,
  IGasDateField,
  IGasForm,
  ReportDialog,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { formatoReporte } from "../../utils/enums";
import { Grid } from "@mui/material";
import { formatDateTimeUS, useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../api/services/service";
import { downloadFile, showInWindow } from "../../components/helpers/documents";
import { esFechaValida, fechasMismoMes, rangoFechasValido } from "../../utils/functions";
import {
  clearErrors,
  resumenDeVentasPorCombustible,
  resumenFaltantesYSobrantes,
  resumenPorRangoFechas,
  setNombreReporte,
} from "../../store/reportes/reportesLiq";

const NOW = new Date();

/*Se crean los diferentes tipos de reportes 
  que se utilizarán para las peticiones*/
const tiposReporte = [
  {
    id: 1,
    tipoReporte: "Resumen de Ventas",
  },
  {
    id: 2,
    tipoReporte: "Resumen de Ventas por Combustible",
  },
  {
    id: 3,
    tipoReporte: "Resumen de Faltantes y Sobrantes",
  },
];

export function ConfigReportesResumen({ tipoReporte }) {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.reportesLiq);

  const [open, setOpen] = useState(true);
  const [formato, setFormato] = useState(formatoReporte.PDF);
  const [errorMessage, setErrorMessage] = useState("");

  const navigator = useNavigate();
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  const modalExport = useModal(false);

  const handleSubmit = (data) => {
    setErrorMessage("");
    if (!esFechaValida(data.fechaIni)) {
      setErrorMessage((e) => ({
        ...e,
        fechaIni: "FechaIni no tiene un formato de fecha válido.",
      }));
      return;
    }
    if (!esFechaValida(data.fechaFin)) {
      setErrorMessage((e) => ({
        ...e,
        fechaFin: "FechaFin no tiene un formato de fecha válido.",
      }));
      return;
    }
    if (rangoFechasValido(data.fechaIni, data.fechaFin)) {
      setErrorMessage((e) => ({
        ...e,
        fechaIni: "'Fecha inicial' no debe ser mayor a 'Fecha final.'",
      }));
      return;
    }
    // if ((tipoReporte === 2 || tipoReporte === 3) && !fechasMismoMes(data.fechaIni, data.fechaFin)) {
    //   setErrorMessage((e) => ({
    //     ...e,
    //     fechaFin: "FechaFin debe de estar dentro del mismo mes.",
    //   }));
    //   return;
    // }

    modalExport.open();
    if (tipoReporte === 1) {
      dispatch(resumenPorRangoFechas({ ...data, formato: formato }))
        .then(hasDispatchError)
        .then(formato === formatoReporte.XLS ? downloadFile : showInWindow)
        .then(modalExport.close)
        .catch(modalExport.close);
    } else if (tipoReporte === 2) {
      dispatch(resumenDeVentasPorCombustible({ ...data, formato: formato }))
        .then(hasDispatchError)
        .then(formato === formatoReporte.XLS ? downloadFile : showInWindow)
        .then(modalExport.close)
        .catch(modalExport.close);
    } else if (tipoReporte === 3) {
      dispatch(resumenFaltantesYSobrantes({ ...data, formato: formato }))
        .then(hasDispatchError)
        .then(formato === formatoReporte.XLS ? downloadFile : showInWindow)
        .then(modalExport.close)
        .catch(modalExport.close);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    navigator("/");
  };

  const handleCloseError = () => {
    setErrorMessage("");
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status >= 500 && dangerMsg.open();
    }
  };

  const initialize = () => {
    handleCloseError();
    dispatch(
      setNombreReporte(
        tipoReporte === 1
          ? "Resumen de Ventas"
          : tipoReporte === 2
          ? "Resumen de Ventas por Combustible"
          : "Resumen de Faltantes y Sobrantes"
      )
    );
  };

  useEffect(initialize, []);
  useEffect(handleError, [error]);

  return (
    <>
      <ReportDialog
        open={open}
        onClose={handleClose}
        title={tiposReporte.find((x) => x.id === tipoReporte).tipoReporte}
        maxWidth='xs'
        onsetFormat={setFormato}
      >
        <IGasForm id='resumen-rango-fechas-dlg' onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <IGasDateField
                autoFocus
                label='De la fecha:'
                name='fechaIni'
                defaultValue={formatDateTimeUS(NOW)}
                error={errorMessage?.fechaIni}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <IGasDateField
                label='A la fecha:'
                name='fechaFin'
                defaultValue={formatDateTimeUS(NOW)}
                error={errorMessage?.fechaFin}
              />
            </Grid>

            {tipoReporte === 1 && (
              <Grid item xs={12} sm={12}>
                <IGasCheckboxField label='Ocultar productos sin ventas' name='productosSinVentas' defaultValue={true} />
              </Grid>
            )}

            {tipoReporte === 2 && (
              <Grid item xs={12} sm={12}>
                <IGasCheckboxField label='Incluir resumen de IEPS' name='mostrarIEPS' defaultValue={true} />
              </Grid>
            )}
          </Grid>
        </IGasForm>
      </ReportDialog>

      <ExportDialog open={modalExport.isShowing} onClose={modalExport.close} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}

export default ConfigReportesResumen;
