import api from "../service";

const _controller = "dashboardliquidacion/dashboard";

export async function GetVentasCombustibles(params) {
  const res = await api.get(
    `${_controller}/GetVentaCombustibles?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}`
  );
  return res.data;
}

export async function GetVentasCombustiblesDetalle(params) {
  const res = await api.get(
    `${_controller}/GetVentasCombustibleDetalle?fechaIni=${params.fechaIni}&fechaFin=${params.fechaFin}&porMes=${params.porMes}`
  );
  return res.data;
}

export async function GetResumenVentasDiversos(params) {
  const res = await api.get(`${_controller}/GetResumenDiversos`, { params });
  return res.data;
}

export async function GetResumenVentasDespachador(params) {
  const res = await api.get(`${_controller}/GetVentasDespachador`, { params });
  return res.data;
}

export async function GetAlertas(params) {
  const res = await api.get(`${_controller}/GetAlertas`, { params });
  return res.data;
}

export default {
  GetVentasCombustibles,
  GetVentasCombustiblesDetalle,
  GetResumenVentasDiversos,
  GetResumenVentasDespachador,
  GetAlertas,
};
