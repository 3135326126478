import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { IGasDecimalField, IGasForm } from "@paul-igas/igas-react-components";

export const LecturasEditDialog = ({ open, onClose, postSubmit, data }) => {
  const [errors, setErrors] = useState();

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const validaForm = (comb) => {
    const newErrors = {};

    newErrors.lecturaFinal = comb.lecturaFinal.toString() === "" ? "'Lectura final' es requerida." : "";
    if (newErrors.lecturaFinal === "") {
      newErrors.lecturaFinal =
        parseFloat(comb.lecturaFinal) < data.lecturaInicial
          ? `'Lectura final' debe ser mayor o igual a ${data.lecturaInicial}`
          : "";
    }
    setErrors(newErrors);

    return Object.values(newErrors).every((x) => x === "");
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
    }
  };

  const handleEdit = (comb) => {
    if (validaForm(comb)) {
      const lecturaFinal = parseFloat(comb.lecturaFinal).toFixed(3);
      const diferencia =
        lecturaFinal -
        data.lecturaInicial -
        data.devolucion -
        data.consignacion -
        data.diferenciaLecturas -
        data.diferenciaLecturas2;
      const importe = diferencia * data.precioCombustible;
      const newData = {
        ...data,
        lecturaFinal,
        diferencia,
        importe,
        lecturaProductoModificado: true,
      };
      onClose();
      if (newData) {
        data = { ...data, lecturaProductoModificado: true };
      }
      postSubmit(newData);
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>Editar lectura final</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{`Dispensario: ${data?.noDispensario} - ${data?.nombreCombustible}`}</DialogContentText>
          <br />
          <IGasForm id='lecturas-edit-dlg' onSubmit={handleEdit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IGasDecimalField
                  autoFocus
                  label='Lectura final'
                  name='lecturaFinal'
                  defaultValue={Number(data?.lecturaFinal)}
                  maxLength={11}
                  error={errors?.lecturaFinal}
                  decimalScale={3}
                />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={false} type='submit' form='lecturas-edit-dlg'>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LecturasEditDialog;
