import { useEffect, useState } from "react";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  createTheme,
  styled,
} from "@mui/material";
import { monedaMX } from "@paul-igas/igas-react-hooks";
import OptionTableRowProd from "./components/optionTableRowProd";

const CellHead = styled(TableCell)(() => ({ fontWeight: "bold" }));

export const TableProductosMobile = (props) => {
  const { productos, openEditDialog, openDetailDialog, totales, screen } = props;

  return (
    <TableContainer>
      <Table size='small' padding='checkbox'>
        <TableHeadProductoMobile screen={screen} />
        <TableBody>
          {productos.map((prod) => (
            <RowProductoMobile
              key={prod.id}
              rowData={prod}
              openEditDialog={openEditDialog}
              openDetailDialog={openDetailDialog}
              screen={screen}
            />
          ))}
        </TableBody>
        <TotalsProductoMobile totalsRow={totales} screen={screen} />
      </Table>
    </TableContainer>
  );
};

export default TableProductosMobile;

const TableHeadProductoMobile = ({ screen }) => {
  const [columns, setColumns] = useState(screen === "sm");

  useEffect(() => {
    setColumns(screen === "sm");
  }, [screen]);

  return (
    <TableHead style={{ backgroundColor: "whitesmoke" }}>
      <TableRow>
        <CellHead>Descripción</CellHead>
        <CellHead align='right'>Venta</CellHead>
        {columns && <CellHead align='right'>Precio</CellHead>}
        <CellHead align='right'>Importe</CellHead>
        <CellHead></CellHead>
      </TableRow>
    </TableHead>
  );
};

const RowProductoMobile = (props) => {
  const { rowData, openEditDialog, openDetailDialog, screen } = props;
  const [columns, setColumns] = useState(screen === "sm");

  useEffect(() => {
    setColumns(screen === "sm");
  }, [screen]);

  return (
    <TableRow>
      <TableCellLink onDetail={openDetailDialog} data={rowData}>{`${rowData.descripcion.substring(
        0,
        columns ? 16 : 12
      )}...`}</TableCellLink>
      <TableCell align='right' padding='none'>
        {rowData.venta}
      </TableCell>
      {columns && <TableCell align='right'>{monedaMX.format(rowData.precioProducto)}</TableCell>}
      <TableCell align='right' padding='none'>
        {monedaMX.format(rowData.importe)}
      </TableCell>
      <TableCell align='right' padding='none'>
        <OptionTableRowProd data={rowData} onEdit={openEditDialog} />
      </TableCell>
    </TableRow>
  );
};

const TotalsProductoMobile = ({ totalsRow, screen }) => {
  const [columns, setColumns] = useState(screen === "sm");

  useEffect(() => {
    setColumns(screen === "sm");
  }, [screen]);

  return (
    <TableFooter>
      <TableRow>
        <CellHead variant='head' align='right' padding='none'>
          Total
        </CellHead>
        <CellHead variant='head' align='right' padding='none'>
          {totalsRow[0].venta}
        </CellHead>
        {columns && (
          <TableCell align='right' padding='none'>
            {totalsRow[0].sep1}
          </TableCell>
        )}
        <CellHead variant='head' align='right' padding='none'>
          {monedaMX.format(totalsRow[0].importe)}
        </CellHead>
      </TableRow>
    </TableFooter>
  );
};

const TableCellLink = (props) => {
  const { children, onDetail = () => {}, data } = props;
  const theme = createTheme();

  const handleDetail = () => {
    onDetail(data);
  };

  return (
    <TableCell align='left' padding='none'>
      <Link
        component='button'
        variant='body2'
        onClick={handleDetail}
        sx={{ color: theme.palette.info.main }}
        underline='hover'
      >
        {children}
      </Link>
    </TableCell>
  );
};
