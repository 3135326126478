import { Box, Card, CardContent, Divider, Hidden, Skeleton } from "@mui/material";
import { useWidth } from "../../../hooks";

export const InfoCard = ({ title = "", detail = {}, info = {}, loading = false }) => {
  const screen = useWidth();

  return (
    <Card>
      <CardContent style={{ maxHeight: "170px", overflow: "auto" }}>
        {title}
        {loading ? (
          <Skeleton animation='wave' />
        ) : (
          <Box display='flex' flexDirection={screen === "xs" || screen === "sm" ? "column" : "row"}>
            <Box mr={2}>{info}</Box>
            <Hidden only={"xs"}>
              <Divider orientation='vertical' flexItem />
            </Hidden>
            <Box flexGrow={1} ml={2} pt={1}>
              {detail}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
