import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { DetailAlign, DetailDataType, DetailInfo } from "@paul-igas/igas-react-components";
import { GetConfiguration } from "../../../../helpers/variablesEstacion";
import ListadoPreciosDetalle from "../components/listadoPreciosDetalle";

export const CombustibleDetailDialog = ({ open, onClose, data }) => {
  const manejaConsignacion = GetConfiguration(7)?.valor;

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>Detalle de lecturas</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{`Dispensario: ${data?.noDispensario} - ${data?.nombreCombustible}`}</DialogContentText>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <DetailInfo
              title='Lectura Inicial'
              description={data.lecturaInicial}
              dataType={DetailDataType.Decimal}
              digitos={3}
              dataAlign={DetailAlign.Right}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DetailInfo
              title='Lectura Final'
              description={data.lecturaFinal}
              dataType={DetailDataType.Decimal}
              digitos={3}
              dataAlign={DetailAlign.Right}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <DetailInfo
              title='Vol.Jarreo'
              description={data.devolucion}
              dataType={DetailDataType.Decimal}
              digitos={3}
              dataAlign={DetailAlign.Right}
            />
          </Grid>
          {Boolean(manejaConsignacion) && (
            <Grid item xs={4} sm={3}>
              <DetailInfo
                title='Consignación'
                description={data.consignacion}
                dataType={DetailDataType.Decimal}
                digitos={3}
                dataAlign={DetailAlign.Right}
              />
            </Grid>
          )}
          <Grid item xs={4} sm={3}>
            <DetailInfo
              title='Vol.Venta'
              description={data.diferencia}
              dataType={DetailDataType.Decimal}
              digitos={3}
              dataAlign={DetailAlign.Right}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            {!Boolean(data.detalle) && (
              <DetailInfo
                title='Precio'
                description={data.precioCombustible}
                dataType={DetailDataType.Decimal}
                digitos={3}
                dataAlign={DetailAlign.Right}
              />
            )}
            {Boolean(data.detalle) && <DetailInfo title='Precio' description='-' dataAlign={DetailAlign.Center} />}
          </Grid>
          <Grid item xs={6} sm={3}>
            <DetailInfo
              title='Importe'
              description={data.importe}
              dataType={DetailDataType.Money}
              dataAlign={DetailAlign.Right}
            />
          </Grid>
        </Grid>
        {Boolean(data.detalle) && <ListadoPreciosDetalle detalle={data.detalle} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CombustibleDetailDialog;
