import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Alertas,
  MenuDashboard,
  VentasCombustibles,
  VentasCombustiblesDetalle,
  VentasPorDespachador,
  VentasProductoDiverso,
} from "../components/dashboard";
import { Parametros } from "../components/dashboard/configuracion";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "260px",
}));

const DashboardMui = () => {
  const [periodo, setPeriodo] = useState(Parametros.mes);
  const [unidad, setUnidad] = useState(Parametros.importe);

  return (
    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12} display='flex' justifyContent='center' alignItems='center'>
        <MenuDashboard onPeriodoChange={setPeriodo} onUnidadChange={setUnidad} periodo={periodo} unidad={unidad} />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={4}>
          <Item>
            <VentasCombustibles unidad={unidad} periodo={periodo} />
          </Item>
        </Grid>

        <Grid item xs={12} md={8}>
          <Item>
            <VentasCombustiblesDetalle pPer={periodo} pUni={unidad} />
          </Item>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Item>
          <VentasProductoDiverso pPer={periodo} pUni={unidad} />
        </Item>
      </Grid>
      <Grid item xs={12} md={4}>
        <Item>
          <VentasPorDespachador pPer={periodo} pUni={unidad} />
        </Item>
      </Grid>

      <Grid item xs={12} md={4}>
        <Alertas />
      </Grid>
    </Grid>
  );
};

export default DashboardMui;
