const formatoFecha = /^\d{4}-\d{2}-\d{2}$/;

export function esFechaValida(fecha) {
  if (!formatoFecha.test(fecha)) {
    return false;
  }
  var partesFecha = fecha.split("-");
  var anio = parseInt(partesFecha[0], 10);
  var mes = parseInt(partesFecha[1], 10);
  var dia = parseInt(partesFecha[2], 10);
  var fechaObjeto = new Date(anio, mes - 1, dia);
  return fechaObjeto.getFullYear() === anio && fechaObjeto.getMonth() === mes - 1 && fechaObjeto.getDate() === dia;
}

export function fechasMismoMes(fechaIni, fechaFin) {
  if (!formatoFecha.test(fechaIni) || !formatoFecha.test(fechaFin)) {
    return false;
  }
  var partesFecha1 = fechaIni.split("-");
  var mes1 = parseInt(partesFecha1[1], 10);
  var partesFecha2 = fechaFin.split("-");
  var mes2 = parseInt(partesFecha2[1], 10);
  return mes1 === mes2;
}

export function rangoFechasValido(fechaIni, fechaFin) {
  if (!formatoFecha.test(fechaIni) || !formatoFecha.test(fechaFin)) {
    return false;
  }
  return new Date(fechaIni) > new Date(fechaFin);
}
