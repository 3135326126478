import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";
import { clearErrors, getResumenVentasDespachador } from "../../store/consultas/dashboard";
import { hasDispatchError } from "../../api/services/service";
import { formatNumber, getParametrosFecha, getTotal, Parametros } from "./configuracion";
import { decimalVal, formatDateTimeUS, monedaMX, useModal } from "@paul-igas/igas-react-hooks";
import { DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { EmptyPie, GraphicTitle } from "./pieComponents";

export const VentasPorDespachador = ({ pPer, pUni }) => {
  const dispatch = useDispatch();
  const { loading, ventasDespachador, error } = useSelector((state) => state.dashboard);

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const params = getParametrosFecha(pPer);

  const margins = { top: 5, bottom: 20, left: 50, right: 50 };
  const outerRadius = 100;

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const initialize = () => {
    handleCloseError();
    dispatch(
      getResumenVentasDespachador({
        fechaIni: formatDateTimeUS(params.fechaIni),
        fechaFin: formatDateTimeUS(params.fechaFin),
        orderByImporte: pUni === Parametros.importe,
      })
    )
      .then(hasDispatchError)
      .catch(() => {});
  };

  const total = getTotal(pUni, ventasDespachador);

  useEffect(initialize, [pPer]);
  useEffect(handleError, [error]);

  return (
    <>
      <GraphicTitle
        loading={loading.getResumenVentasDespachador}
        caption={`Ventas ${pPer === Parametros.anio ? "anuales" : "mensuales"} por despachador ${
          pUni === Parametros.importe ? "$" : ""
        }${formatNumber(total)} ${pUni === Parametros.litros ? "(lts)" : ""}`}
      />

      {loading.getResumenVentasDespachador && <Skeleton animation='pulse' width='100%' height='100%' />}

      {!loading.getResumenVentasDespachador && total <= 0 && <EmptyPie msg='No hay ventas en el periodo' pUni={pUni} />}

      {!loading.getResumenVentasDespachador && total > 0 && (
        <PieChart
          margin={margins}
          series={[
            {
              data: ventasDespachador.map((d) => ({
                label: d.label,
                id: d.id,
                value: pUni === Parametros.importe ? d.importe : d.value,
                color: d.color,
              })),
              arcLabelMinAngle: 15,
              arcLabel: (params) => {
                const percent = params.value / total;
                return `${decimalVal(0).format(percent * 100)}%`;
              },
              valueFormatter:
                pUni === Parametros.importe
                  ? (ventas) => monedaMX.format(ventas.value)
                  : (ventas) => `${new decimalVal(0).format(ventas.value)} (lts)`,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
              innerRadius: 60,
              outerRadius,
              paddingAngle: 2,
              cornerRadius: 5,
              startAngle: 0,
              endAngle: 360,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontSize: 14,
            },
          }}
          slotProps={{
            legend: { hidden: true },
          }}
        />
      )}
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
};

export default VentasPorDespachador;
