import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBackdrop,
  setProfile,
  setState,
  STATES,
  setConfigurations,
  getConfigurations,
  setAlertMessage,
} from "../store/aplicacion";
import { Outlet, useLocation } from "react-router-dom";
import { Loading, NotFound, Forbidden } from "../pages/auth";
import { getUser, isAuth, login } from "../api/authService";
import { Authorize } from "../layout/authorize/authorize";
import { validRoles } from "./helpers/user";
import { Anonymous } from "../layout/anonymous/anonymous";
import { hasErrorRedux } from "../api/services/service";

export default function AuthRoute() {
  const { routes, profile, state, backdrop, progresBarApp, alertMessage } = useSelector((state) => state.aplicacion);
  const _path = useLocation();
  const dispatch = useDispatch();
  const initProfile = async (state) => {
    const user = await getUser();

    dispatch(setProfile(user?.profile));
    dispatch(setState(state));
  };

  const validAuth = async () => {
    const res = await isAuth();

    if (res === 200) return initProfile(STATES.AUTHORIZED);
    if (res === 403) return initProfile(STATES.FORBIDDEN);

    return await login();
  };

  const validRole = () => {
    try {
      //Obtiene route
      const route = flatArray(routes).find((route) => route.route === _path.pathname);
      //Valida rol
      return !route.roles || validRoles(profile.role, route.roles) ? <Outlet /> : <Forbidden />;
    } catch {
      return <NotFound />;
    }
  };

  useEffect(() => {
    if (state === STATES.AUTHORIZED) {
      dispatch(getConfigurations(2))
        .then(hasErrorRedux)
        .then((data) => dispatch(setConfigurations(data)))
        .catch(() => {});
    }
  }, [state]);

  useEffect(() => {
    validAuth();
  }, []);

  if (state === STATES.LOADING)
    return (
      <Anonymous>
        <Loading />
      </Anonymous>
    );
  if (state === STATES.FORBIDDEN)
    return (
      <Anonymous>
        <Forbidden activeLogout={true} />
      </Anonymous>
    );
  if (state === STATES.AUTHORIZED)
    return (
      <Authorize
        title='Liquidaciones'
        routes={routes}
        profile={profile}
        backdrop={backdrop}
        setBackdrop={(value) => dispatch(setBackdrop(value))}
        progresBarApp={progresBarApp}
        alertMessage={alertMessage}
        onCloseAlertMessage={(_) => dispatch(setAlertMessage({}))}
      >
        {validRole()}
      </Authorize>
    );
}

function flatArray(array) {
  return array.reduce((acc, val) => {
    acc.push(val);
    if (val.childrens && val.childrens.length > 0) acc.push.apply(acc, flatArray(val.childrens));
    return acc;
  }, []);
}
