import { useState } from "react";
import { Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Edit, MoreVert, LocalGasStation } from "@mui/icons-material";
import { validRoles } from "../../../../helpers/user";
import { GetConfiguration } from "../../../../helpers/variablesEstacion";
import { useSelector } from "react-redux";

export const OptionTableRowComb = (props) => {
  const manejaConsignacion = GetConfiguration(7)?.valor;
  const [anchorEl, setAnchorEl] = useState(null);
  const { tienePermiso } = GetInfo();
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { data = {}, onEdit = () => {}, onEditLecturas = () => {}, esUltimoTurno = () => {} } = props;

  const handleEdit = () => {
    handleClose();
    onEdit(data);
  };

  const handleEditLecturas = () => {
    handleClose();
    onEditLecturas(data);
  };

  return (
    <>
      <Button size='small' disableElevation onClick={handleOpen} sx={{ minWidth: 0 }}>
        <MoreVert fontSize='small' color='action' />
      </Button>

      {Boolean(anchorEl) && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {!esUltimoTurno && (
            <MenuItem dense onClick={handleEditLecturas}>
              <ListItemIcon>
                <LocalGasStation fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>Editar lectura final</Typography>
            </MenuItem>
          )}
          <MenuItem dense onClick={handleEdit} disabled={!tienePermiso || Math.abs(data.diferencia) <= 0}>
            <ListItemIcon>
              <Edit fontSize='small' />
            </ListItemIcon>
            {manejaConsignacion ? (
              <Typography variant='inherit'>Editar jarreo/consignación</Typography>
            ) : (
              <Typography variant='inherit'>Editar jarreo</Typography>
            )}
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default OptionTableRowComb;

const GetInfo = () => {
  const { profile } = useSelector((state) => state.aplicacion);

  return {
    tienePermiso: validRoles(profile.role, "Administrador"),
  };
};
