import { getOnlyActiveValues } from "@paul-igas/igas-react-hooks";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Delete,
  GetAgrupadoFecha,
  GetUltimo,
  GetUltimoAbierto,
  Insert,
  SetClose,
  SetOpen,
} from "../../api/services/operaciones/turnos";
import { GetCajeros } from "../../api/services/consultas/usuarios";
import { hasErrorRedux } from "../../api/services/service";
import { ExportResumenTurno } from "../../api/services/reportes/turnosLiquidacion";

export const getAgrupadoFecha = createAsyncThunk("turnos/agrupadoFecha", async (params, { rejectWithValue }) => {
  try {
    return await GetAgrupadoFecha(getOnlyActiveValues(params));
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getUltimo = createAsyncThunk("turnos/ultimo", async (_, { rejectWithValue }) => {
  try {
    return await GetUltimo();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getUltimoAbierto = createAsyncThunk("turnos/ultimoAbierto", async (_, { rejectWithValue }) => {
  try {
    return await GetUltimoAbierto();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getCajeros = createAsyncThunk("turnos/cajeros", async (_, { rejectWithValue }) => {
  try {
    return await GetCajeros();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("turnos/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const putClose = createAsyncThunk("turnos/cerrar", async (params, { rejectWithValue }) => {
  try {
    return await SetClose(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const putOpen = createAsyncThunk("turnos/abrir", async (params, { rejectWithValue }) => {
  try {
    return await SetOpen(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const exportResumenTurno = createAsyncThunk(
  "liquidacion/exportResumenTurno",
  async (params, { rejectWithValue }) => {
    try {
      return await ExportResumenTurno(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const remove = createAsyncThunk("turnos/delete", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

// const dteIni = new Date();
// const dteFin = new Date();

const initialState = {
  turnos: [],
  cajeros: [],
  error: {},
  turno: {},
  loading: {
    getAgrupadoFecha: false,
    getUltimo: false,
    getUltimoAbierto: false,
    getCajeros: false,
    setAbrir: false,
    setCerrar: false,
    delete: false,
    insert: false,
    exportarResumenTurno: false,
  },
  // filter: {
  //   fechaIni: {
  //     state: true,
  //     value: formatDateTimeUS(dteIni.setDate(dteFin.getDate() - 1)),
  //     label: "Fecha Inicial",
  //   },
  //   fechaFin: { state: true, value: formatDateTimeUS(dteFin), label: "Fecha Final" },
  //   idUsuario: { state: false, value: "", label: "Cajero" },
  // },
  filter: {
    fechaIni: { state: true, value: "", label: "Fecha inicial" },
    fechaFin: { state: true, value: "", label: "Fecha final" },
    idUsuario: { state: true, value: "", label: "Cajero" },
  },
};

const slice = createSlice({
  name: "turnos",
  initialState, 
  reducers: {
    setTurno: (state, action) => {
      state.turno = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgrupadoFecha.pending, (state) => {
      state.loading.getAgrupadoFecha = true;
    });
    builder.addCase(getAgrupadoFecha.fulfilled, (state, action) => {
      state.error = {};
      state.turnos = action.payload;
      state.loading.getAgrupadoFecha = false;
    });
    builder.addCase(getAgrupadoFecha.rejected, (state, action) => {
      state.error = action.payload;
      state.turnos = [];
      state.loading.getAgrupadoFecha = false;
    });
    builder.addCase(getUltimo.pending, (state) => {
      state.loading.getUltimo = true;
    });
    builder.addCase(getUltimo.fulfilled, (state) => {
      state.error = {};
      state.loading.getUltimo = false;
    });
    builder.addCase(getUltimo.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getUltimo = false;
    });
    builder.addCase(getUltimoAbierto.pending, (state) => {
      state.loading.getUltimoAbierto = true;
    });
    builder.addCase(getUltimoAbierto.fulfilled, (state) => {
      state.error = {};
      state.loading.getUltimoAbierto = false;
    });
    builder.addCase(getUltimoAbierto.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getUltimoAbierto = false;
    });
    builder.addCase(getCajeros.pending, (state) => {
      state.loading.getCajeros = true;
    });
    builder.addCase(getCajeros.fulfilled, (state, action) => {
      state.error = {};
      state.cajeros = action.payload;
      state.loading.getCajeros = false;
    });
    builder.addCase(getCajeros.rejected, (state, action) => {
      state.error = action.payload;
      state.cajeros = [];
      state.loading.getCajeros = false;
    });
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    builder.addCase(putOpen.pending, (state) => {
      state.loading.setAbrir = true;
    });
    builder.addCase(putOpen.fulfilled, (state) => {
      state.error = {};
      state.loading.setAbrir = false;
    });
    builder.addCase(putOpen.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.setAbrir = false;
    });
    builder.addCase(putClose.pending, (state) => {
      state.loading.setCerrar = true;
    });
    builder.addCase(putClose.fulfilled, (state) => {
      state.error = {};
      state.loading.setCerrar = false;
    });
    builder.addCase(putClose.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.setCerrar = false;
    });
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.error = {};
      state.loading.delete = false;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
    //  Exportar resumen del turno
    builder.addCase(exportResumenTurno.pending, (state) => {
      state.loading.exportarResumenTurno = true;
    });
    builder.addCase(exportResumenTurno.fulfilled, (state) => {
      state.error = {};
      state.loading.exportarResumenTurno = false;
    });
    builder.addCase(exportResumenTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.exportarResumenTurno = false;
    });
  },
});

export default slice.reducer;
export const { setFilter, clearErrors, setTurno } = slice.actions;
