import { useState } from "react";
import { UseAuthContext, UseAuthTheme } from "../../../contexts";
import { Tooltip, Paper, Popper, IconButton, Avatar, Divider, Typography, ClickAwayListener } from "@mui/material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockIcon from "@mui/icons-material/Lock";
import { useModal } from "@paul-igas/igas-react-hooks";

export default function MenuUser() {
  const theme = UseAuthTheme();
  const { profile, logoutDialog } = UseAuthContext();
  const resetPassword = useModal(false);
  const menu = useModal(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    menu.toggle();
  }

  function handleResetPassword() {
    resetPassword.open();
    menu.close();
  }

  if (!profile) return null;

  return (
    <>
      <Tooltip title='Perfil de usuario'>
        <IconButton onClick={handleClick} color='inherit'>
          <Avatar
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: theme.spacing(3),
              height: theme.spacing(3),
            }}
          >
            {profile.fullName.charAt(0).toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      {menu.isShowing && (
        <Popper
          open={menu.isShowing}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          sx={{
            zIndex: theme.zIndex.appBar + 1,
            [theme.breakpoints.up("sm")]: {
              zIndex: theme.zIndex.drawer + 2,
            },
          }}
        >
          <ClickAwayListener onClickAway={menu.close}>
            <Paper>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem>
                  <ListItemText>
                    <Typography variant='body1' display='block' noWrap={true}>
                      {profile.fullName}
                    </Typography>
                    <Typography variant='body2' display='block' noWrap={true}>
                      {profile.email}
                    </Typography>
                    <Typography variant='caption' display='block' noWrap={true}>
                      {Array.isArray(profile.role) ? profile.role.join(", ") : profile.role}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List dense={true}>
                {/* <ListItemButton onClick={handleResetPassword}>
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText primary='Cambiar contraseña' />
                </ListItemButton> */}

                <ListItemButton onClick={logoutDialog.open}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary='Cerrar sesión' />
                </ListItemButton>
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}
